import { checkAuth, hasToken, refreshToken } from "../services/authService";
import AuthWrapper, {
  AuthContext,
  IAuthContext,
} from "../components/AuthWrapper";
import React, { useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import { WalletConnectors } from "../components";

export const useAuth = (): IAuthContext => useContext(AuthContext);
const walletConnectors = new WalletConnectors([1, 56, 137]);
const AuthWrapperWithContext = ({
  children,
  isOpenProviderMenu,
  onOpenProviderMenu,
  onCloseProviderMenu,
}: {
  children: React.ReactElement | React.ReactElement[];
  isOpenProviderMenu: boolean;

  onOpenProviderMenu: () => void;
  onCloseProviderMenu: () => void;
}): React.ReactElement => {
  const web3React = useWeb3React();

  return (
    <AuthWrapper
      walletConnectors={walletConnectors}
      dexGuruAPIV2Url={"https://auth-api-stage.dex.guru/v2"}
      dexGuruAPIV3Url={"https://auth-api-stage.dex.guru/v3"}
      sdkCheckAuth={checkAuth}
      sdkHasToken={hasToken}
      sdkRefreshToken={refreshToken}
      onWalletConnectionError={async (error) => console.log(error)}
      web3React={web3React}
      onOpenProviderMenu={onOpenProviderMenu}
      onCloseProviderMenu={onCloseProviderMenu}
      isOpenProviderMenu={isOpenProviderMenu}
    >
      {children}
    </AuthWrapper>
  );
};
export default AuthWrapperWithContext;
