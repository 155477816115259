import { NetworksConfigV3 } from "./models/model";

export type NetworksConfig = NetworksConfigV3;

const config: NetworksConfig[] = [
  {
    id: 1,
    name: "eth",
    description: "Ethereum",
    type: "evm",
    logo_uri: "https://assets-stage.dex.guru/icons/chain-1.svg",
    enabled: true,
    rpc_url: "https://mainnet.infura.io/v3/12583d4bd6354f1f9144d25402e42eb2",
    gas_buffer: 50000000000000000,
    balances_wss_request: null,
    color: "#858585",
    primary_token_address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    secondary_token_address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    amms: [
      {
        id: "1",
        chain_id: 1,
        name: "uniswap",
        description: "ETH: Uniswap V2",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-uniswap_v2.png",
        border_color: "#ea6394",
        start_gradient_color: "rgba(65, 22, 148, 0.6)",
        end_gradient_color: "rgba(133, 48, 135, 0.6)",
        display_name: "Uniswap V2",
      },
      {
        id: "2",
        chain_id: 1,
        name: "uniswap_v3",
        description: "ETH: Uniswap V3",
        type: "uniswap_v3",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/1/0xE592427A0AEce92De3Edee1F18E0157C05861564.svg",
        border_color: "#00aeb9",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "Uniswap V3",
      },
      {
        id: "3",
        chain_id: 1,
        name: "sushiswap",
        description: "ETH: Sushiswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/1/0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F.svg",
        border_color: "#f371f5",
        start_gradient_color: "rgba(17, 55, 114, 0.6)",
        end_gradient_color: "rgba(135, 48, 48, 0.6)",
        display_name: "SushiSwap",
      },
      {
        id: "12",
        chain_id: 1,
        name: "kyber",
        description: "ETH: Kyber",
        type: "dmm",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/1/0x1c87257F5e8609940Bc751a07BB085Bb7f8cDBE6.svg",
        border_color: "#ea6394",
        start_gradient_color: "rgba(65, 22, 148, 0.6)",
        end_gradient_color: "rgba(133, 48, 135, 0.6)",
        display_name: "KyberSwap Classic",
      },
      {
        id: "17",
        chain_id: 1,
        name: "cryptocom",
        description: "ETH: Crypto.com",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/1/0x68a7ca3d4ee5f1711776b4bd75c1dfa8b8e96083.svg",
        border_color: "#0046b6",
        start_gradient_color: "rgba(103, 87, 0, 0.6)",
        end_gradient_color: "rgba(0, 25, 63, 0.6)",
        display_name: "Crypto.com",
      },
      {
        id: "59",
        chain_id: 1,
        name: "swapr",
        description: "Ethereum: Swapr",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/1/0xb9960d9bca016e9748be75dd52f02188b9d0829f.svg",
        border_color: "#b43bff",
        start_gradient_color: "#3bffff",
        end_gradient_color: "#330052",
        display_name: "Swapr",
      },
      {
        id: "66",
        chain_id: 1,
        name: "standard",
        description: "Ethereum: Standard",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/1/0x6c78d71ce7300343e3633e0c45d6c7a79fee4650.svg",
        border_color: "#cfa7ec",
        start_gradient_color: "#9e43bc",
        end_gradient_color: "#28112f",
        display_name: "Standard",
      },
      {
        id: "73",
        chain_id: 1,
        name: "shibaswap",
        description: "Ethereum: ShibaSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/1/0x03f7724180aa6b939894b5ca4314783b0b36b329.svg",
        border_color: "#ffbe3c",
        start_gradient_color: "#936200",
        end_gradient_color: "#5a3c00",
        display_name: "ShibaSwap",
      },
      {
        id: "78",
        chain_id: 1,
        name: "curve",
        description: "ETH: Curve",
        type: "curve",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/curve.svg",
        border_color: "#ff3f29",
        start_gradient_color: "#faff00",
        end_gradient_color: "#002fff",
        display_name: "Curve",
      },
      {
        id: "79",
        chain_id: 1,
        name: "1inch",
        description: "ETH: 1inch",
        type: "1inch",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-1inch.svg",
        border_color: "#ff2f30",
        start_gradient_color: "#9cb1d4",
        end_gradient_color: "#1b314f",
        display_name: "1inch",
      },
      {
        id: "87",
        chain_id: 1,
        name: "bancor_v2",
        description: "ETH: Bancor",
        type: "bancor_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-bancor.svg",
        border_color: "#0dcce0",
        start_gradient_color: "#7ea8e1",
        end_gradient_color: "#441033",
        display_name: "Bancor V2",
      },
      {
        id: "92",
        chain_id: 1,
        name: "dodo",
        description: "ETH: DODO",
        type: "dodo",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-dodo.svg",
        border_color: "#06c9ff",
        start_gradient_color: "#b906ff",
        end_gradient_color: "#ff7b06",
        display_name: "DODO",
      },
      {
        id: "102",
        chain_id: 1,
        name: "fraxswap",
        description: "ETH: Fraxswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-fraxswap.svg\t",
        border_color: "#a8a8a8",
        start_gradient_color: "#464646",
        end_gradient_color: "#0d0d0d",
        display_name: "Fraxswap",
      },
      {
        id: "108",
        chain_id: 1,
        name: "stepn",
        description: "ETH: Stepn",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-stepn.svg",
        border_color: "#c3a6f9",
        start_gradient_color: "#a6e8f7",
        end_gradient_color: "#51d274",
        display_name: "STEPN",
      },
      {
        id: "124",
        chain_id: 1,
        name: "saddle",
        description: "ETH: Saddle",
        type: "saddle",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/saddle_finance.svg",
        border_color: "#9d70ff",
        start_gradient_color: "#4b11f2",
        end_gradient_color: "#a286e0",
        display_name: "Saddle",
      },
      {
        id: "125",
        chain_id: 1,
        name: "balancer",
        description: "ETH: Balancer",
        type: "balancer",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/balancer-amm.svg",
        border_color: "#4c4b9b",
        start_gradient_color: "#648ff8",
        end_gradient_color: "#191b22",
        display_name: "Balancer",
      },
      {
        id: "137",
        chain_id: 1,
        name: "pancakeswap",
        description: "ETH: Pancakeswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x10ED43C718714eb63d5aA57B78B54704E256024E.svg",
        border_color: "#63eab9",
        start_gradient_color: "rgba(16, 68, 144, 0.6)",
        end_gradient_color: "rgba(33, 136, 111, 0.6)",
        display_name: "PancakeSwap",
      },
      {
        id: "143",
        chain_id: 1,
        name: "radioshack",
        description: "ETH: RadioShack",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-radioshack.svg",
        border_color: "#ff0000",
        start_gradient_color: "#0d0314",
        end_gradient_color: "#370a14",
        display_name: "RadioShack",
      },
      {
        id: "148",
        chain_id: 1,
        name: "kyberswap_elastic",
        description: "ETH: KyberSwap Elastic",
        type: "kyberswap_elastic",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#24b2bc",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "KyberSwap Elastic",
      },
      {
        id: "150",
        chain_id: 1,
        name: "apeswap",
        description: "ETH: ApeSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7.svg",
        border_color: "#ffe934",
        start_gradient_color: "#a16552",
        end_gradient_color: "#533329",
        display_name: "ApeSwap",
      },
    ],
    most_liquid_tokens: [
      {
        id: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2-eth",
        address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
        name: "Wrapped Ether",
        symbols: ["WETH"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48-eth",
        address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
        name: "USD Coin USDC",
        symbols: ["USDC"],
        logoURI: [],
        decimals: 6,
      },
      {
        id: "0xdac17f958d2ee523a2206206994597c13d831ec7-eth",
        address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
        name: "Tether USD",
        symbols: ["USDT"],
        logoURI: [],
        decimals: 6,
      },
      {
        id: "0x514910771af9ca656af840dff83e8264ecf986ca-eth",
        address: "0x514910771af9ca656af840dff83e8264ecf986ca",
        name: "ChainLink Token",
        symbols: ["LINK"],
        logoURI: [],
        decimals: 18,
      },
    ],
    native_token: {
      id: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2-eth",
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      name: "Wrapped Ether",
      symbols: ["WETH"],
      logoURI: [],
      decimals: 18,
    },
    block_explorer: {
      url: "https://etherscan.io",
      logo_uri: "https://assets-stage.dex.guru/icons/explorer-1.png",
      display_name: "Etherscan",
      token_path: "/token",
      address_path: "/address",
    },
    zerox_api: {
      url: "https://api.0x.org",
      wrapper_url: "https://api-trading.dex.guru/eth",
      gas_url: "https://gas.api.0x.org",
      wrapper_gas_url: "https://api-trading.dex.guru/eth/gas_prices",
      spender_address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
    },
    one_inch_api: {
      url: "https://limit-orders.1inch.io/v2.0/1/limit-order",
      wrapper_url: "https://api-trading.dex.guru/eth/limit",
      gas_url: "https://api-trading.dex.guru/eth/gas_prices",
      wrapper_gas_url: "https://api-trading.dex.guru/eth/gas_prices",
      spender_address: "0x119c71d3bbac22029622cbaec24854d3d32d2828",
    },
    market_order: {
      url: "https://api-trading.dex.guru/market/eth",
      spender_addresses: [
        {
          name: "0x",
          address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
          provider: "zero_x",
        },
        {
          name: "1inch",
          address: "0x1111111254eeb25477b68fb85ed929f73a960582",
          provider: "one_inch",
        },
        {
          name: "ParaSwap",
          address: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
          provider: "paraswap",
        },
      ],
    },
    limit_order: {
      url: "https://api-trading.dex.guru/limit/eth",
      spender_addresses: [
        {
          name: "1inch",
          address: "0x119c71d3bbac22029622cbaec24854d3d32d2828",
          provider: "one_inch",
        },
      ],
    },
    gas_url: "https://api-trading.dex.guru/gas_prices/eth",
  },
  {
    id: 10,
    name: "optimism",
    description: "Optimism",
    type: "evm",
    logo_uri: "https://assets-stage.dex.guru/icons/chain-10.svg",
    enabled: true,
    rpc_url: "https://mainnet.optimism.io",
    gas_buffer: 1000000000000000,
    balances_wss_request: null,
    color: "#fd0026",
    primary_token_address: "0x4200000000000000000000000000000000000006",
    secondary_token_address: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
    amms: [
      {
        id: "55",
        chain_id: 10,
        name: "uniswap_v3",
        description: "Optimism: Uniswap V3",
        type: "uniswap_v3",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-uniswap_v3.png",
        border_color: "#00aeb9",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "Uniswap V3",
      },
      {
        id: "75",
        chain_id: 10,
        name: "zipswap",
        description: "Optimism: ZipSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/10/0xE6Df0BB08e5A97b40B21950a0A51b94c4DbA0Ff6.svg",
        border_color: "#01e1f3",
        start_gradient_color: "#01f38c",
        end_gradient_color: "#0168f3",
        display_name: "ZipSwap",
      },
      {
        id: "84",
        chain_id: 10,
        name: "curve",
        description: "Optimism: Curve",
        type: "curve",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-curve.svg",
        border_color: "#ff3f29",
        start_gradient_color: "#faff00",
        end_gradient_color: "#002fff",
        display_name: "Curve",
      },
      {
        id: "91",
        chain_id: 10,
        name: "velodrome",
        description: "Optimism: Velodrome",
        type: "solidly",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-velodrome.svg",
        border_color: "#06c9ff",
        start_gradient_color: "#b906ff",
        end_gradient_color: "#ff7b06",
        display_name: "Velodrome",
      },
      {
        id: "120",
        chain_id: 10,
        name: "sushiswap_bento",
        description: "Optimism: Sushiswap",
        type: "sushiswap_bento",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-sushiswap.svg",
        border_color: "#f371f5",
        start_gradient_color: "#113772",
        end_gradient_color: "#873030",
        display_name: "Sushiswap Trident",
      },
      {
        id: "126",
        chain_id: 10,
        name: "saddle",
        description: "Optimism: Saddle",
        type: "saddle",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/saddle_finance.svg",
        border_color: "#9d70ff",
        start_gradient_color: "#4b11f2",
        end_gradient_color: "#a286e0",
        display_name: "Saddle",
      },
      {
        id: "136",
        chain_id: 10,
        name: "beethoven",
        description: "OPTIMISM: Beethoven",
        type: "balancer",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-beethoven_X.svg",
        border_color: "#00f4ad",
        start_gradient_color: "#10bb89",
        end_gradient_color: "#181964",
        display_name: "Beethoven X",
      },
      {
        id: "147",
        chain_id: 10,
        name: "radioshack",
        description: "Optimism: RadioShack",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-radioshack.svg",
        border_color: "#ff0000",
        start_gradient_color: "#0d0314",
        end_gradient_color: "#370a14",
        display_name: "RadioShack",
      },
      {
        id: "157",
        chain_id: 10,
        name: "kyberswap_elastic",
        description: "Optimism: KyberSwap Elastic",
        type: "kyberswap_elastic",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#24b2bc",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "KyberSwap Elastic",
      },
      {
        id: "158",
        chain_id: 10,
        name: "kyber",
        description: "Optimism: Kyber",
        type: "dmm",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#ea6394",
        start_gradient_color: "rgba(65, 22, 148, 0.6)",
        end_gradient_color: "rgba(133, 48, 135, 0.6)",
        display_name: "KyberSwap Classic",
      },
    ],
    most_liquid_tokens: [
      {
        id: "0x4200000000000000000000000000000000000006-optimism",
        address: "0x4200000000000000000000000000000000000006",
        name: "Wrapped Ether",
        symbols: ["WETH"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x7f5c764cbc14f9669b88837ca1490cca17c31607-optimism",
        address: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
        name: "USD Coin",
        symbols: ["USDC"],
        logoURI: [],
        decimals: 6,
      },
      {
        id: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb-optimism",
        address: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
        name: "Lyra",
        symbols: ["LYRA"],
        logoURI: [],
        decimals: 18,
      },
    ],
    native_token: {
      id: "0x4200000000000000000000000000000000000006-optimism",
      address: "0x4200000000000000000000000000000000000006",
      name: "Wrapped Ether",
      symbols: ["WETH"],
      logoURI: [],
      decimals: 18,
    },
    block_explorer: {
      url: "https://optimistic.etherscan.io",
      logo_uri: "https://assets-stage.dex.guru/icons/chain-10.svg",
      display_name: "The Optimism Explorer",
      token_path: "/token",
      address_path: "/address",
    },
    zerox_api: {
      url: "https://optimism.api.0x.org",
      wrapper_url: "https://api-trading.dex.guru/optimism",
      gas_url: "https://gas.optimism.api.0x.org",
      wrapper_gas_url: "https://api-trading.dex.guru/optimism/gas_prices",
      spender_address: "0xdef1abe32c034e558cdd535791643c58a13acc10",
    },
    one_inch_api: {
      url: "https://limit-orders.1inch.io/v2.0/10/limit-order",
      wrapper_url: "https://api-trading.dex.guru/optimism/limit",
      gas_url: "https://api-trading.dex.guru/optimism/gas_prices",
      wrapper_gas_url: "https://api-trading.dex.guru/optimism/gas_prices",
      spender_address: "0x11431a89893025d2a48dca4eddc396f8c8117187",
    },
    market_order: {
      url: "https://api-trading.dex.guru/market/optimism",
      spender_addresses: [
        {
          name: "0x",
          address: "0xdef1abe32c034e558cdd535791643c58a13acc10",
          provider: "zero_x",
        },
        {
          name: "1inch",
          address: "0x1111111254eeb25477b68fb85ed929f73a960582",
          provider: "one_inch",
        },
        {
          name: "ParaSwap",
          address: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
          provider: "paraswap",
        },
      ],
    },
    limit_order: {
      url: "https://api-trading.dex.guru/limit/optimism",
      spender_addresses: [
        {
          name: "1inch",
          address: "0x11431a89893025d2a48dca4eddc396f8c8117187",
          provider: "one_inch",
        },
      ],
    },
    gas_url: "https://api-trading.dex.guru/gas_prices/optimism",
  },
  {
    id: 56,
    name: "bsc",
    description: "BSC",
    type: "evm",
    logo_uri: "https://assets-stage.dex.guru/icons/chain-56.svg",
    enabled: true,
    rpc_url: "https://bsc-dataseed.binance.org",
    gas_buffer: 20000000000000000,
    balances_wss_request: null,
    color: "#ff8a1f",
    primary_token_address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    secondary_token_address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    amms: [
      {
        id: "4",
        chain_id: 56,
        name: "pancakeswap",
        description: "BSC: Pancakeswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x10ED43C718714eb63d5aA57B78B54704E256024E.svg",
        border_color: "#63eab9",
        start_gradient_color: "rgba(16, 68, 144, 0.6)",
        end_gradient_color: "rgba(33, 136, 111, 0.6)",
        display_name: "PancakeSwap",
      },
      {
        id: "16",
        chain_id: 56,
        name: "impossible_finance",
        description: "Avalanche: Impossible Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x8f2A0d8865D995364DC6843D51Cf6989001f989e.svg",
        border_color: "#00daff",
        start_gradient_color: "rgba(1, 149, 108, 0.6)",
        end_gradient_color: "rgba(2, 17, 89, 0.6)",
        display_name: "Impossible Finance",
      },
      {
        id: "28",
        chain_id: 56,
        name: "jetswap",
        description: "BSC: JetSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xBe65b8f75B9F20f4C522e0067a3887FADa714800.svg",
        border_color: "#fec803",
        start_gradient_color: "#ab8600",
        end_gradient_color: "#373737",
        display_name: "JetSwap",
      },
      {
        id: "41",
        chain_id: 56,
        name: "waultswap",
        description: "BSC: WaultSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xD48745E39BbED146eEC15b79cBF964884F9877c2.svg",
        border_color: "#ea3a26",
        start_gradient_color: "#6b3f23",
        end_gradient_color: "#110802",
        display_name: "WaultSwap",
      },
      {
        id: "45",
        chain_id: 56,
        name: "cafeswap",
        description: "BSC: CafeSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x933DAea3a5995Fb94b14A7696a5F3ffD7B1E385A.svg",
        border_color: "#ea3a26",
        start_gradient_color: "#6b3f23",
        end_gradient_color: "#110802",
        display_name: "CafeSwap",
      },
      {
        id: "46",
        chain_id: 56,
        name: "alitaswap",
        description: "BSC: AlitaSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x730aCC3bBf2443f2EaEaCFc7ac7b4d8DC9E32dB8.svg",
        border_color: "#ceeaff",
        start_gradient_color: "#3b52ff",
        end_gradient_color: "#030a45",
        display_name: "AlitaSwap",
      },
      {
        id: "48",
        chain_id: 56,
        name: "apeswap",
        description: "BSC: ApeSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7.svg",
        border_color: "#ffdf34",
        start_gradient_color: "#a16552",
        end_gradient_color: "#533329",
        display_name: "ApeSwap",
      },
      {
        id: "49",
        chain_id: 56,
        name: "cheeseswap",
        description: "BSC: CheeseSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xb4bf64b17e270b50d00658e3c0e2fbdefabdd87b.svg",
        border_color: "#ffea9b",
        start_gradient_color: "#ffea9b",
        end_gradient_color: "#086411",
        display_name: "CheeseSwap",
      },
      {
        id: "50",
        chain_id: 56,
        name: "julswap",
        description: "BSC: JulSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xbd67d157502A23309Db761c41965600c2Ec788b2.svg",
        border_color: "#6f5ac6",
        start_gradient_color: "#6f5ac6",
        end_gradient_color: "#65c3aa",
        display_name: "JulSwap",
      },
      {
        id: "51",
        chain_id: 56,
        name: "hyperswap",
        description: "BSC: Hyperswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x3bc677674df90A9e5D741f28f6CA303357D0E4Ec.svg",
        border_color: "#3ad8ff",
        start_gradient_color: "#ff454c",
        end_gradient_color: "#190600",
        display_name: "HyperSwap",
      },
      {
        id: "52",
        chain_id: 56,
        name: "babyswap",
        description: "BSC: BabySwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x325E343f1dE602396E256B67eFd1F61C3A6B38Bd.svg",
        border_color: "#ffb332",
        start_gradient_color: "#492504",
        end_gradient_color: "#dbff00",
        display_name: "BabySwap",
      },
      {
        id: "53",
        chain_id: 56,
        name: "safeswap",
        description: "BSC: SafeSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xe804f3c3e6dda8159055428848fe6f2a91c2b9af.svg",
        border_color: "#181ed1",
        start_gradient_color: "#9093ef",
        end_gradient_color: "#151860",
        display_name: "SafeSwap",
      },
      {
        id: "54",
        chain_id: 56,
        name: "elk_finance",
        description: "BSC: Elk Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xA63B831264183D755756ca9AE5190fF5183d65D6.svg",
        border_color: "#00bb64",
        start_gradient_color: "rgba(0, 93, 202, 0.6)",
        end_gradient_color: "rgba(0, 71, 38, 0.6)",
        display_name: "Elk Finance",
      },
      {
        id: "56",
        chain_id: 56,
        name: "kyber",
        description: "BSC: Kyber",
        type: "dmm",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#ea6394",
        start_gradient_color: "rgba(65, 22, 148, 0.6)",
        end_gradient_color: "rgba(133, 48, 135, 0.6)",
        display_name: "KyberSwap Classic",
      },
      {
        id: "60",
        chain_id: 56,
        name: "pancakeswap_v1",
        description: "BSC: pancakeswap_v1",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F.svg",
        border_color: "#ffbc57",
        start_gradient_color: "#873e03",
        end_gradient_color: "#29c9d7",
        display_name: "Pancakeswap v1",
      },
      {
        id: "62",
        chain_id: 56,
        name: "sushiswap",
        description: "BSC: SushiSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/42161/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506.png",
        border_color: "#f371f5",
        start_gradient_color: "rgba(17, 55, 114, 0.6)",
        end_gradient_color: "rgba(17, 55, 114, 0.6)",
        display_name: "SushiSwap",
      },
      {
        id: "68",
        chain_id: 56,
        name: "biswap",
        description: "BSC: Biswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8.svg",
        border_color: "#34ef41",
        start_gradient_color: "#13ddec",
        end_gradient_color: "#1371ec",
        display_name: "Biswap",
      },
      {
        id: "69",
        chain_id: 56,
        name: "yieldfields",
        description: "BSC: YieldFields",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xa4385646517d9b34ada4ac393a64ea120d08408c.svg",
        border_color: "#f0b21a",
        start_gradient_color: "#f0471a",
        end_gradient_color: "#f01a58",
        display_name: "Yieldfields",
      },
      {
        id: "70",
        chain_id: 56,
        name: "unitedfarmers",
        description: "BSC: United Farmers",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xad02320a81606fbB760C32e065495A8ddbf322A8.svg",
        border_color: "#f9e91c",
        start_gradient_color: "#89ec06",
        end_gradient_color: "#3b6503",
        display_name: "United Farmers",
      },
      {
        id: "74",
        chain_id: 56,
        name: "sheepdex",
        description: "BSC: SheepDex",
        type: "uniswap_v3",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-uniswap_v3.png",
        border_color: "#00aeb9",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "SheepDex",
      },
      {
        id: "76",
        chain_id: 56,
        name: "bakeryswap",
        description: "BSC: BakerySwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0xCDe540d7eAFE93aC5fE6233Bee57E1270D3E330F.svg",
        border_color: "#ffbe3c",
        start_gradient_color: "#936200",
        end_gradient_color: "#5a3c00",
        display_name: "BakerySwap",
      },
      {
        id: "86",
        chain_id: 56,
        name: "ellipsis",
        description: "BSC: Ellipsis",
        type: "ellipsis",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-ellipsis.svg",
        border_color: "#df90da",
        start_gradient_color: "#c25cdf",
        end_gradient_color: "#225789",
        display_name: "Ellipsis",
      },
      {
        id: "90",
        chain_id: 56,
        name: "dodo",
        description: "BSC: DODO",
        type: "dodo",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-dodo.svg",
        border_color: "#06c9ff",
        start_gradient_color: "#b906ff",
        end_gradient_color: "#ff7b06",
        display_name: "DODO",
      },
      {
        id: "96",
        chain_id: 56,
        name: "knightswap",
        description: "BSC: KnightSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-knightswap.svg",
        border_color: "#034d8f",
        start_gradient_color: "#a9b7bf",
        end_gradient_color: "#3c4448",
        display_name: "KnightSwap",
      },
      {
        id: "97",
        chain_id: 56,
        name: "mdex",
        description: "BSC: MDEX",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-mdex.svg",
        border_color: "#4ba8db",
        start_gradient_color: "#0289d3",
        end_gradient_color: "#131735",
        display_name: "MDEX",
      },
      {
        id: "101",
        chain_id: 56,
        name: "safemoon",
        description: "BSC: Safemoon",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets.dex.guru/icons/amm-bsc-safemoon.svg",
        border_color: "#0fd7ca",
        start_gradient_color: "#1d4142",
        end_gradient_color: "#191b22",
        display_name: "Safemoon",
      },
      {
        id: "105",
        chain_id: 56,
        name: "fraxswap",
        description: "BSC: Fraxswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-fraxswap.svg",
        border_color: "#a8a8a8",
        start_gradient_color: "#464646",
        end_gradient_color: "#0d0d0d",
        display_name: "Fraxswap",
      },
      {
        id: "109",
        chain_id: 56,
        name: "stepn",
        description: "BSC: Stepn",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-stepn.svg",
        border_color: "#c3a6f9",
        start_gradient_color: "#a6e8f7",
        end_gradient_color: "#51d274",
        display_name: "STEPN",
      },
      {
        id: "110",
        chain_id: 56,
        name: "fstswap",
        description: "BSC: Fstswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-fstswap.svg",
        border_color: "#f6c214",
        start_gradient_color: "#ffe797",
        end_gradient_color: "#e27c03",
        display_name: "Fstswap",
      },
      {
        id: "111",
        chain_id: 56,
        name: "pandora",
        description: "BSC: Pandora",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-pandora.svg",
        border_color: "#fef8ff",
        start_gradient_color: "#ffe797",
        end_gradient_color: "#e27c03",
        display_name: "Pandora",
      },
      {
        id: "112",
        chain_id: 56,
        name: "nomiswap",
        description: "BSC: Nomiswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-nomiswap.svg",
        border_color: "#052987",
        start_gradient_color: "#3299ff",
        end_gradient_color: "#66c0ff",
        display_name: "Nomiswap",
      },
      {
        id: "113",
        chain_id: 56,
        name: "planet",
        description: "BSC: Planet",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-planet.svg\t",
        border_color: "#003445",
        start_gradient_color: "#1f123e",
        end_gradient_color: "#55b7ff",
        display_name: "Planet",
      },
      {
        id: "114",
        chain_id: 56,
        name: "jfswap",
        description: "BSC: JFswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-jfswap.svg",
        border_color: "#908ee5",
        start_gradient_color: "#f9f9ff",
        end_gradient_color: "#403ce4",
        display_name: "JFswap",
      },
      {
        id: "115",
        chain_id: 56,
        name: "titano",
        description: "BSC: Titano",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-titano.svg",
        border_color: "#70e1c5",
        start_gradient_color: "#2ee3b6",
        end_gradient_color: "#131b2a",
        display_name: "Titano",
      },
      {
        id: "116",
        chain_id: 56,
        name: "orbitalswap",
        description: "BSC: OrbitalSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-orbitalswap.svg",
        border_color: "#b0e5dd",
        start_gradient_color: "#5de49f",
        end_gradient_color: "#7b57ce",
        display_name: "OrbitalSwap",
      },
      {
        id: "121",
        chain_id: 56,
        name: "impossible_finance_v3",
        description: "BSC: Impossible Finance V3",
        type: "solidly",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/56/0x8f2A0d8865D995364DC6843D51Cf6989001f989e.svg",
        border_color: "#00daff",
        start_gradient_color: "rgba(1, 149, 108, 0.6)",
        end_gradient_color: "rgba(2, 17, 89, 0.6)",
        display_name: "Impossible Finance V3",
      },
      {
        id: "138",
        chain_id: 56,
        name: "babydogeswap",
        description: "BSC: BabyDogeSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-babydogeswap.png",
        border_color: "#fb6300",
        start_gradient_color: "#b0dfff",
        end_gradient_color: "#3670ab",
        display_name: "BabyDogeSwap",
      },
      {
        id: "144",
        chain_id: 56,
        name: "radioshack",
        description: "BSC: RadioShack",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-radioshack.svg",
        border_color: "#ff0000",
        start_gradient_color: "#0d0314",
        end_gradient_color: "#370a14",
        display_name: "RadioShack",
      },
      {
        id: "149",
        chain_id: 56,
        name: "padswap",
        description: "BSC: PadSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-padswap.svg",
        border_color: "#1fde4e",
        start_gradient_color: "#e190dc",
        end_gradient_color: "#fb40ee",
        display_name: "PadSwap",
      },
      {
        id: "152",
        chain_id: 56,
        name: "kyberswap_elastic",
        description: "BSC: KyberSwap Elastic",
        type: "kyberswap_elastic",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#24b2bc",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "KyberSwap Elastic",
      },
    ],
    most_liquid_tokens: [
      {
        id: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c-bsc",
        address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
        name: "Wrapped BNB",
        symbols: ["WBNB"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0xe9e7cea3dedca5984780bafc599bd69add087d56-bsc",
        address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        name: "BUSD Token",
        symbols: ["BUSD"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x55d398326f99059ff775485246999027b3197955-bsc",
        address: "0x55d398326f99059ff775485246999027b3197955",
        name: "Tether Stablecoin",
        symbols: ["USDT"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c-bsc",
        address: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
        name: "Binance Bitcoin",
        symbols: ["BTCB"],
        logoURI: [],
        decimals: 18,
      },
    ],
    native_token: {
      id: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c-bsc",
      address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
      name: "Wrapped BNB",
      symbols: ["WBNB"],
      logoURI: [],
      decimals: 18,
    },
    block_explorer: {
      url: "https://bscscan.com",
      logo_uri: "https://assets-stage.dex.guru/icons/explorer-56.png",
      display_name: "BscScan",
      token_path: "/token",
      address_path: "/address",
    },
    zerox_api: {
      url: "https://bsc.api.0x.org",
      wrapper_url: "https://api-trading.dex.guru/bsc",
      gas_url: "https://gas.bsc.api.0x.org",
      wrapper_gas_url: "https://api-trading.dex.guru/bsc/gas_prices",
      spender_address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
    },
    one_inch_api: {
      url: "https://limit-orders.1inch.io/v2.0/56/limit-order",
      wrapper_url: "https://api-trading.dex.guru/bsc/limit",
      gas_url: "https://api-trading.dex.guru/bsc/gas_prices",
      wrapper_gas_url: "https://api-trading.dex.guru/bsc/gas_prices",
      spender_address: "0x1e38eff998df9d3669e32f4ff400031385bf6362",
    },
    market_order: {
      url: "https://api-trading.dex.guru/market/bsc",
      spender_addresses: [
        {
          name: "0x",
          address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
          provider: "zero_x",
        },
        {
          name: "1inch",
          address: "0x1111111254eeb25477b68fb85ed929f73a960582",
          provider: "one_inch",
        },
        {
          name: "ParaSwap",
          address: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
          provider: "paraswap",
        },
      ],
    },
    limit_order: {
      url: "https://api-trading.dex.guru/limit/bsc",
      spender_addresses: [
        {
          name: "1inch",
          address: "0x1e38eff998df9d3669e32f4ff400031385bf6362",
          provider: "one_inch",
        },
      ],
    },
    gas_url: "https://api-trading.dex.guru/gas_prices/bsc",
  },
  {
    id: 100,
    name: "gnosis",
    description: "Gnosis",
    type: "evm",
    logo_uri: "https://assets-stage.dex.guru/icons/chain-100.svg",
    enabled: true,
    rpc_url: "https://gnosischain-rpc.gateway.pokt.network/",
    gas_buffer: 5000000000000000,
    balances_wss_request: null,
    color: "#008b73",
    primary_token_address: "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d",
    secondary_token_address: "0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1",
    amms: [
      {
        id: "122",
        chain_id: 100,
        name: "sushiswap",
        description: "GNOSIS: Sushiswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-sushiswap.png",
        border_color: "#981f1f",
        start_gradient_color: "rgba(17, 55, 114, 0.6)",
        end_gradient_color: "rgba(135, 48, 48, 0.6)",
        display_name: "SushiSwap",
      },
      {
        id: "123",
        chain_id: 100,
        name: "swapr",
        description: "Gnosis: Swapr",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/1/0xb9960d9bca016e9748be75dd52f02188b9d0829f.svg",
        border_color: "#c41bd0",
        start_gradient_color: "#3bffff",
        end_gradient_color: "#330052",
        display_name: "Swapr",
      },
      {
        id: "128",
        chain_id: 100,
        name: "honeyswap",
        description: "Gnosis: Honeyswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/honeyswap.svg",
        border_color: "#3b4b4c",
        start_gradient_color: "#89a4a6",
        end_gradient_color: "#232f32",
        display_name: "Honeyswap",
      },
      {
        id: "129",
        chain_id: 100,
        name: "baoswap",
        description: "Gnosis: Bao Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/bao_finance.svg",
        border_color: "#ff8884",
        start_gradient_color: "#ffd1a7",
        end_gradient_color: "#831500",
        display_name: "BaoSwap",
      },
      {
        id: "130",
        chain_id: 100,
        name: "levin_swap",
        description: "Gnosis: Levin Swap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/levinswap.svg",
        border_color: "#a27395",
        start_gradient_color: "#e1b4d5",
        end_gradient_color: "#240f47",
        display_name: "Levin Swap",
      },
      {
        id: "131",
        chain_id: 100,
        name: "elk_finance",
        description: "Gnosis: Elk Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/elk_finance.svg",
        border_color: "#00bb64",
        start_gradient_color: "#005dca",
        end_gradient_color: "#004726",
        display_name: "Elk Finance",
      },
      {
        id: "132",
        chain_id: 100,
        name: "curve",
        description: "Gnosis: Curve",
        type: "curve",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/curve.svg",
        border_color: "#ff3f29",
        start_gradient_color: "#faff00",
        end_gradient_color: "#002fff",
        display_name: "Curve",
      },
      {
        id: "133",
        chain_id: 100,
        name: "empiredex",
        description: "Gnosis: EmpireDEX",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/empireDEX.svg",
        border_color: "#ff9900",
        start_gradient_color: "#68695d",
        end_gradient_color: "#282b28",
        display_name: "EmpireDEX",
      },
    ],
    most_liquid_tokens: [
      {
        id: "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d-gnosis",
        address: "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d",
        name: "Wrapped xDAI",
        symbols: ["xDAI"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1-gnosis",
        address: "0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1",
        name: "Wrapped ETH",
        symbols: ["WETH"],
        logoURI: [],
        decimals: 18,
      },
    ],
    native_token: {
      id: "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d-gnosis",
      address: "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d",
      name: "Wrapped XDAI",
      symbols: ["WXDAI"],
      logoURI: [],
      decimals: 18,
    },
    block_explorer: {
      url: "https://gnosisscan.io/",
      logo_uri: "https://assets-stage.dex.guru/icons/explorer-100.svg",
      display_name: "GnosisScan",
      token_path: "/token",
      address_path: "/address",
    },
    zerox_api: {
      url: "https://api.1inch.io/",
      wrapper_url: "https://api-trading.dex.guru/gnosis",
      gas_url: null,
      wrapper_gas_url: "https://api-trading.dex.guru/gnosis/gas_prices",
      spender_address: "0x54431918cec22932fcf97e54769f4e00f646690f",
    },
    one_inch_api: {
      url: "https://limit-orders.1inch.io/v2.0/100/limit-order",
      wrapper_url: "https://api-trading.dex.guru/limit/gnosis",
      gas_url: "https://api-trading.dex.guru/gas_prices/gnosis",
      wrapper_gas_url: "https://api-trading.dex.guru/gas_prices/gnosis",
      spender_address: "0x54431918cec22932fcf97e54769f4e00f646690f",
    },
    market_order: {
      url: "https://api-trading.dex.guru/market/gnosis",
      spender_addresses: [
        {
          name: "1inch",
          address: "0x1111111254eeb25477b68fb85ed929f73a960582",
          provider: "one_inch",
        },
      ],
    },
    limit_order: {
      url: "https://api-trading.dex.guru/limit/gnosis",
      spender_addresses: [
        {
          name: "1inch",
          address: "0x54431918cec22932fcf97e54769f4e00f646690f",
          provider: "one_inch",
        },
      ],
    },
    gas_url: "https://api-trading.dex.guru/gas_prices/gnosis",
  },
  {
    id: 137,
    name: "polygon",
    description: "Polygon",
    type: "evm",
    logo_uri: "https://assets-stage.dex.guru/icons/chain-137.svg",
    enabled: true,
    rpc_url: "https://rpc-mainnet.maticvigil.com",
    gas_buffer: 150000000000000000,
    balances_wss_request: null,
    color: "#863ff8",
    primary_token_address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    secondary_token_address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    amms: [
      {
        id: "5",
        chain_id: 137,
        name: "quickswap",
        description: "Polygon: QuickSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff.svg",
        border_color: "#6e80ff",
        start_gradient_color: "rgba(106, 26, 7, 0.6)",
        end_gradient_color: "rgba(100, 97, 30, 0.6)",
        display_name: "QuickSwap",
      },
      {
        id: "18",
        chain_id: 137,
        name: "firebid_finance",
        description: "Polygon: Firebird Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0xF6fa9Ea1f64f1BBfA8d71f7f43fAF6D45520bfac.svg",
        border_color: "#0085ff",
        start_gradient_color: "rgba(35, 66, 167, 0.6)",
        end_gradient_color: "rgba(117, 0, 0, 0.6)",
        display_name: "Firebird Finance",
      },
      {
        id: "21",
        chain_id: 137,
        name: "uniswap_v3",
        description: "Polygon: Uniswap V3",
        type: "uniswap_v3",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0xE592427A0AEce92De3Edee1F18E0157C05861564.svg",
        border_color: "#00aeb9",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "Uniswap V3",
      },
      {
        id: "29",
        chain_id: 137,
        name: "jetswap",
        description: "Polygon: JetSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0x5C6EC38fb0e2609672BDf628B1fD605A523E5923.svg",
        border_color: "#fec803",
        start_gradient_color: "#ab8600",
        end_gradient_color: "#373737",
        display_name: "JetSwap",
      },
      {
        id: "37",
        chain_id: 137,
        name: "dfyn",
        description: "Polygon: Dfyn",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0xA102072A4C07F06EC3B4900FDC4C7B80b6c57429.svg",
        border_color: "#d14a8a",
        start_gradient_color: "#258bbf",
        end_gradient_color: "#921950",
        display_name: "Dfyn",
      },
      {
        id: "40",
        chain_id: 137,
        name: "waultswap",
        description: "Polygon: WaultSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0x3a1d87f206d12415f5b0a33e786967680aab4f6d.svg",
        border_color: "#ea3a26",
        start_gradient_color: "#6b3f23",
        end_gradient_color: "#110802",
        display_name: "WaultSwap",
      },
      {
        id: "42",
        chain_id: 137,
        name: "polydex",
        description: "Polygon: PolyDex",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0xC60aE14F2568b102F8Ca6266e8799112846DD088.svg",
        border_color: "#9033ff",
        start_gradient_color: "#c798ff",
        end_gradient_color: "#8f31ff",
        display_name: "PolyDex",
      },
      {
        id: "43",
        chain_id: 137,
        name: "elk_finance",
        description: "Polygon: Elk Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0xf38a7A7Ac2D745E2204c13F824c00139DF831FFf.svg",
        border_color: "#00bb64",
        start_gradient_color: "rgba(0, 93, 202, 0.6)",
        end_gradient_color: "rgba(0, 71, 38, 0.6)",
        display_name: "Elk Finance",
      },
      {
        id: "44",
        chain_id: 137,
        name: "cafeswap",
        description: "Polygon: CafeSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0x9055682E58C74fc8DdBFC55Ad2428aB1F96098Fc.svg",
        border_color: "#ea3a26",
        start_gradient_color: "#6b3f23",
        end_gradient_color: "#110802",
        display_name: "CafeSwap",
      },
      {
        id: "47",
        chain_id: 137,
        name: "apeswap",
        description: "Polygon: ApeSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607.svg",
        border_color: "#ffdf34",
        start_gradient_color: "#a16552",
        end_gradient_color: "#533329",
        display_name: "ApeSwap",
      },
      {
        id: "57",
        chain_id: 137,
        name: "kyber",
        description: "Polygon: Kyber",
        type: "dmm",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#ea6394",
        start_gradient_color: "rgba(65, 22, 148, 0.6)",
        end_gradient_color: "rgba(133, 48, 135, 0.6)",
        display_name: "KyberSwap Classic",
      },
      {
        id: "61",
        chain_id: 137,
        name: "sushiswap",
        description: "Polygon: SushiSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-sushiswap.svg",
        border_color: "#f371f5",
        start_gradient_color: "rgba(17, 55, 114, 0.6)",
        end_gradient_color: "rgba(135, 48, 48, 0.6)",
        display_name: "SushiSwap",
      },
      {
        id: "67",
        chain_id: 137,
        name: "polycat",
        description: "Polygon: Polycat",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0x94930a328162957FF1dd48900aF67B5439336cBD.svg",
        border_color: "#ffffff",
        start_gradient_color: "#707070",
        end_gradient_color: "#0c0c0c",
        display_name: "Polycat",
      },
      {
        id: "71",
        chain_id: 137,
        name: "gravity_finance",
        description: "Polygon: GravityFinance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0x57de98135e8287f163c59ca4ff45f1341b680248.svg",
        border_color: "#ffbe3c",
        start_gradient_color: "#936200",
        end_gradient_color: "#5a3c00",
        display_name: "Gravity Finance",
      },
      {
        id: "85",
        chain_id: 137,
        name: "curve",
        description: "Polygon: Curve",
        type: "curve",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-curve.svg",
        border_color: "#ff3f29",
        start_gradient_color: "#faff00",
        end_gradient_color: "#002fff",
        display_name: "Curve",
      },
      {
        id: "94",
        chain_id: 137,
        name: "dodo",
        description: "Polygon: DODO",
        type: "dodo",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-dodo.svg",
        border_color: "#06c9ff",
        start_gradient_color: "#b906ff",
        end_gradient_color: "#ff7b06",
        display_name: "DODO",
      },
      {
        id: "98",
        chain_id: 137,
        name: "meshswap",
        description: "Polygon: Meshswap",
        type: "meshswap",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets.dex.guru/icons/amm-meshswap.svg",
        border_color: "#ab83f2",
        start_gradient_color: "#648ff8",
        end_gradient_color: "#875fe3",
        display_name: "Meshswap",
      },
      {
        id: "100",
        chain_id: 137,
        name: "comethswap",
        description: "Polygon: ComethSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets.dex.guru/icons/amm-comethswap.svg",
        border_color: "#00b9f2",
        start_gradient_color: "#0092bf",
        end_gradient_color: "#003545",
        display_name: "ComethSwap",
      },
      {
        id: "107",
        chain_id: 137,
        name: "fraxswap",
        description: "Polygon: Fraxswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-fraxswap.svg\t",
        border_color: "#a8a8a8",
        start_gradient_color: "#464646",
        end_gradient_color: "#0d0d0d",
        display_name: "Fraxswap",
      },
      {
        id: "119",
        chain_id: 137,
        name: "dystopia",
        description: "Polygon: Dystopia",
        type: "solidly",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-dystopia.svg",
        border_color: "#0e7ab8",
        start_gradient_color: "#0b5e8e",
        end_gradient_color: "#000000",
        display_name: "Dystopia",
      },
      {
        id: "139",
        chain_id: 137,
        name: "radioshack",
        description: "Polygon: RadioShack",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-radioshack.svg",
        border_color: "#ff0000",
        start_gradient_color: "#0d0314",
        end_gradient_color: "#370a14",
        display_name: "RadioShack",
      },
      {
        id: "142",
        chain_id: 137,
        name: "balancer",
        description: "Polygon: Balancer",
        type: "balancer",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/balancer-amm.svg",
        border_color: "#4c4b9b",
        start_gradient_color: "#648ff8",
        end_gradient_color: "#191b22",
        display_name: "Balancer",
      },
      {
        id: "151",
        chain_id: 137,
        name: "sushiswap_bento",
        description: "Polygon: SushiSwap Trident",
        type: "sushiswap_bento",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-sushiswap.svg",
        border_color: "#f371f5",
        start_gradient_color: "#113772",
        end_gradient_color: "#873030",
        display_name: "Sushiswap Trident",
      },
      {
        id: "153",
        chain_id: 137,
        name: "kyberswap_elastic",
        description: "Polygon: KyberSwap Elastic",
        type: "kyberswap_elastic",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#24b29a",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "KyberSwap Elastic",
      },
    ],
    most_liquid_tokens: [
      {
        id: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270-polygon",
        address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
        name: "WMATIC2",
        symbols: ["WMATIC"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619-polygon",
        address: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
        name: "Wrapped Ether",
        symbols: ["WETH"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174-polygon",
        address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
        name: "USDC",
        symbols: ["USDC"],
        logoURI: [],
        decimals: 6,
      },
      {
        id: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f-polygon",
        address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        name: "USDT",
        symbols: ["USDT"],
        logoURI: [],
        decimals: 6,
      },
    ],
    native_token: {
      id: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270-polygon",
      address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
      name: "Wrapped Matic",
      symbols: ["WMATIC"],
      logoURI: [],
      decimals: 18,
    },
    block_explorer: {
      url: "https://polygonscan.com",
      logo_uri: "https://assets-stage.dex.guru/icons/explorer-137.png",
      display_name: "PolygonScan",
      token_path: "/token",
      address_path: "/address",
    },
    zerox_api: {
      url: "https://polygon.api.0x.org",
      wrapper_url: "https://api-trading.dex.guru/polygon",
      gas_url: "https://gas.polygon.api.0x.org",
      wrapper_gas_url: "https://api-trading.dex.guru/polygon/gas_prices",
      spender_address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
    },
    one_inch_api: {
      url: "https://limit-orders.1inch.io/v2.0/137/limit-order",
      wrapper_url: "https://api-trading.dex.guru/polygon/limit",
      gas_url: "https://api-trading.dex.guru/polygon/gas_prices",
      wrapper_gas_url: "https://api-trading.dex.guru/polygon/gas_prices",
      spender_address: "0x94bc2a1c732bcad7343b25af48385fe76e08734f",
    },
    market_order: {
      url: "https://api-trading.dex.guru/market/polygon",
      spender_addresses: [
        {
          name: "0x",
          address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
          provider: "zero_x",
        },
        {
          name: "1inch",
          address: "0x1111111254eeb25477b68fb85ed929f73a960582",
          provider: "one_inch",
        },
        {
          name: "ParaSwap",
          address: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
          provider: "paraswap",
        },
      ],
    },
    limit_order: {
      url: "https://api-trading.dex.guru/limit/polygon",
      spender_addresses: [
        {
          name: "1inch",
          address: "0x94bc2a1c732bcad7343b25af48385fe76e08734f",
          provider: "one_inch",
        },
      ],
    },
    gas_url: "https://api-trading.dex.guru/gas_prices/polygon",
  },
  {
    id: 250,
    name: "fantom",
    description: "Fantom",
    type: "evm",
    logo_uri: "https://assets-stage.dex.guru/icons/chain-250.svg",
    enabled: true,
    rpc_url: "https://rpc.ftm.tools",
    gas_buffer: 200000000000000000,
    balances_wss_request: null,
    color: "#1969ff",
    primary_token_address: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
    secondary_token_address: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
    amms: [
      {
        id: "10",
        chain_id: 250,
        name: "spookyswap",
        description: "Fantom: SpookySwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0xF491e7B69E4244ad4002BC14e878a34207E38c29.svg",
        border_color: "#8667dd",
        start_gradient_color: "rgba(152, 125, 55, 0.6)",
        end_gradient_color: "rgba(145, 106, 255, 0.6)",
        display_name: "SpookySwap",
      },
      {
        id: "11",
        chain_id: 250,
        name: "spiritswap",
        description: "Fantom: SpiritSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52.svg",
        border_color: "#51b6bf",
        start_gradient_color: "rgba(47, 123, 104, 0.6)",
        end_gradient_color: "rgba(17, 28, 47, 0.6)",
        display_name: "SpiritSwap",
      },
      {
        id: "14",
        chain_id: 250,
        name: "sushiswap",
        description: "Fantom: Sushiswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506.svg",
        border_color: "#f371f5",
        start_gradient_color: "rgba(17, 55, 114, 0.6)",
        end_gradient_color: "rgba(135, 48, 48, 0.6)",
        display_name: "SushiSwap",
      },
      {
        id: "26",
        chain_id: 250,
        name: "zoodex",
        description: "Fantom: ZooDex",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0x40b12a3E261416fF0035586ff96e23c2894560f2.svg",
        border_color: "#00b2ff",
        start_gradient_color: "#4bb38e",
        end_gradient_color: "#002b38",
        display_name: "ZooDex",
      },
      {
        id: "27",
        chain_id: 250,
        name: "jetswap",
        description: "Fantom: JetSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0x845E76A8691423fbc4ECb8Dd77556Cb61c09eE25.svg",
        border_color: "#fec803",
        start_gradient_color: "#ab8600",
        end_gradient_color: "#373737",
        display_name: "JetSwap",
      },
      {
        id: "30",
        chain_id: 250,
        name: "soulswap",
        description: "Fantom: SoulSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0xe2fb177009ff39f52c0134e8007fa0e4baacbd07.svg",
        border_color: "#b148ff",
        start_gradient_color: "#e5abff",
        end_gradient_color: "#530092",
        display_name: "SoulSwap",
      },
      {
        id: "31",
        chain_id: 250,
        name: "paintswap",
        description: "Fantom: Paintswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0xfD000ddCEa75a2E23059881c3589F6425bFf1AbB.svg",
        border_color: "#ba57ff",
        start_gradient_color: "#00c596",
        end_gradient_color: "#c32100",
        display_name: "PaintSwap",
      },
      {
        id: "32",
        chain_id: 250,
        name: "hyperswap",
        description: "Fantom: Hyperswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0x53c153a0df7E050BbEFbb70eE9632061f12795fB.svg",
        border_color: "#3ad8ff",
        start_gradient_color: "#ff454c",
        end_gradient_color: "#190600",
        display_name: "HyperSwap",
      },
      {
        id: "33",
        chain_id: 250,
        name: "morpheusswap",
        description: "Fantom: Morpheus Swap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0x8aC868293D97761A1fED6d4A01E9FF17C5594Aa3.svg",
        border_color: "#5efe6d",
        start_gradient_color: "#00c54f",
        end_gradient_color: "#002b04",
        display_name: "Morpheus Swap",
      },
      {
        id: "34",
        chain_id: 250,
        name: "wakaswap",
        description: "Fantom: Wakaswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0xAad831F5CF9E39c666cCba4cF12A70af19782AB9.svg",
        border_color: "#f9f11e",
        start_gradient_color: "#9c960f",
        end_gradient_color: "#211f01",
        display_name: "WakaSwap",
      },
      {
        id: "35",
        chain_id: 250,
        name: "elk_finance",
        description: "Fantom: Elk Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0x4D2cf285a519261F30b4d9c2c344Baf260d65Fa2.svg",
        border_color: "#00bb64",
        start_gradient_color: "rgba(0, 93, 202, 0.6)",
        end_gradient_color: "rgba(0, 71, 38, 0.6)",
        display_name: "Elk Finance",
      },
      {
        id: "36",
        chain_id: 250,
        name: "yoshi_exchange",
        description: "Fantom: Yoshi Exchange",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0x3dc57B391262e3aAe37a08D91241f9bA9d58b570.svg",
        border_color: "#3cf5b4",
        start_gradient_color: "#25c48c",
        end_gradient_color: "#00302a",
        display_name: "Yoshi Exchange",
      },
      {
        id: "38",
        chain_id: 250,
        name: "dfyn",
        description: "Fantom: Dfyn",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/137/0xA102072A4C07F06EC3B4900FDC4C7B80b6c57429.svg",
        border_color: "#d14a8a",
        start_gradient_color: "#258bbf",
        end_gradient_color: "#921950",
        display_name: "Dfyn",
      },
      {
        id: "58",
        chain_id: 250,
        name: "kyber",
        description: "Fantom: Kyber",
        type: "dmm",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#ea6394",
        start_gradient_color: "rgba(65, 22, 148, 0.6)",
        end_gradient_color: "rgba(133, 48, 135, 0.6)",
        display_name: "KyberSwap Classic",
      },
      {
        id: "77",
        chain_id: 250,
        name: "solidly",
        description: "Fantom: Solidly",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0xa38cd27185a464914D3046f0AB9d43356B34829D.svg",
        border_color: "#06d3d7",
        start_gradient_color: "#7306d7",
        end_gradient_color: "#066bd7",
        display_name: "Solidly",
      },
      {
        id: "80",
        chain_id: 250,
        name: "tomb_finance",
        description: "Fantom: Tomb Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/250/0xE236f6890F1824fa0a7ffc39b1597A5A6077Cfe9.svg",
        border_color: "#ffbe3c",
        start_gradient_color: "#936200",
        end_gradient_color: "#5a3c00",
        display_name: "Tomb Finance",
      },
      {
        id: "83",
        chain_id: 250,
        name: "curve",
        description: "Fantom: Curve",
        type: "curve",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-curve.svg",
        border_color: "#ff3f29",
        start_gradient_color: "#faff00",
        end_gradient_color: "#002fff",
        display_name: "Curve",
      },
      {
        id: "106",
        chain_id: 250,
        name: "fraxswap",
        description: "Fantom: Fraxswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-fraxswap.svg\t",
        border_color: "#a8a8a8",
        start_gradient_color: "#464646",
        end_gradient_color: "#0d0d0d",
        display_name: "Fraxswap",
      },
      {
        id: "117",
        chain_id: 250,
        name: "wigoswap",
        description: "Fantom: Wigoswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-wigoswap.svg",
        border_color: "#7788e9",
        start_gradient_color: "#364cc6",
        end_gradient_color: "#0a1829",
        display_name: "WigoSwap",
      },
      {
        id: "135",
        chain_id: 250,
        name: "beethoven",
        description: "FANTOM: Beethoven",
        type: "balancer",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-beethoven_X.svg",
        border_color: "#00f4ad",
        start_gradient_color: "#10bb89",
        end_gradient_color: "#181964",
        display_name: "Beethoven X",
      },
      {
        id: "146",
        chain_id: 250,
        name: "radioshack",
        description: "Fantom: RadioShack",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-radioshack.svg",
        border_color: "#ff0000",
        start_gradient_color: "#0d0314",
        end_gradient_color: "#370a14",
        display_name: "RadioShack",
      },
      {
        id: "155",
        chain_id: 250,
        name: "kyberswap_elastic",
        description: "Fantom: KyberSwap Elastic",
        type: "kyberswap_elastic",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#24b2bc",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "KyberSwap Elastic",
      },
    ],
    most_liquid_tokens: [
      {
        id: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83-fantom",
        address: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        name: "Wrapped FTM",
        symbols: ["WFTM"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x04068da6c83afcfa0e13ba15a6696662335d5b75-fantom",
        address: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
        name: "USD Coin",
        symbols: ["USDC"],
        logoURI: [],
        decimals: 6,
      },
      {
        id: "0xe1146b9ac456fcbb60644c36fd3f868a9072fc6e-fantom",
        address: "0xe1146b9ac456fcbb60644c36fd3f868a9072fc6e",
        name: "fBTC",
        symbols: ["FBTC"],
        logoURI: [],
        decimals: 18,
      },
    ],
    native_token: {
      id: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83-fantom",
      address: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
      name: "Wrapped Fantom",
      symbols: ["WFTM"],
      logoURI: [],
      decimals: 18,
    },
    block_explorer: {
      url: "https://ftmscan.com",
      logo_uri: "https://assets-stage.dex.guru/icons/explorer-250.svg",
      display_name: "FTMScan",
      token_path: "/token",
      address_path: "/address",
    },
    zerox_api: {
      url: "https://fantom.api.0x.org",
      wrapper_url: "https://api-trading.dex.guru/fantom",
      gas_url: "https://gas.fantom.api.0x.org",
      wrapper_gas_url: "https://api-trading.dex.guru/fantom/gas_prices",
      spender_address: "0xdef189deaef76e379df891899eb5a00a94cbc250",
    },
    one_inch_api: {
      url: null,
      wrapper_url: null,
      gas_url: null,
      wrapper_gas_url: null,
      spender_address: "null",
    },
    market_order: {
      url: "https://api-trading.dex.guru/market/fantom",
      spender_addresses: [
        {
          name: "0x",
          address: "0xdef189deaef76e379df891899eb5a00a94cbc250",
          provider: "zero_x",
        },
        {
          name: "ParaSwap",
          address: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
          provider: "paraswap",
        },
        {
          name: "1inch",
          address: "0x1111111254eeb25477b68fb85ed929f73a960582",
          provider: "one_inch",
        },
      ],
    },
    limit_order: { url: null, spender_addresses: [] },
    gas_url: "https://api-trading.dex.guru/gas_prices/fantom",
  },
  {
    id: 42161,
    name: "arbitrum",
    description: "Arbitrum",
    type: "evm",
    logo_uri: "https://assets-stage.dex.guru/icons/chain-42161.svg",
    enabled: true,
    rpc_url: "https://arb1.arbitrum.io/rpc",
    gas_buffer: 100000000000000,
    balances_wss_request: null,
    color: "#7bb6de",
    primary_token_address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    secondary_token_address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    amms: [
      {
        id: "8",
        chain_id: 42161,
        name: "uniswap_v3",
        description: "Arbitrum",
        type: "uniswap_v3",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/42161/0xE592427A0AEce92De3Edee1F18E0157C05861564.svg",
        border_color: "#00aeb9",
        start_gradient_color: "#024764",
        end_gradient_color: "#38a062",
        display_name: "Uniswap V3",
      },
      {
        id: "13",
        chain_id: 42161,
        name: "sushiswap",
        description: "Arbitrum: Sushiswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/42161/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506.svg",
        border_color: "#f371f5",
        start_gradient_color: "rgba(17, 55, 114, 0.6)",
        end_gradient_color: "rgba(135, 48, 48, 0.6)",
        display_name: "SushiSwap",
      },
      {
        id: "39",
        chain_id: 42161,
        name: "dfyn",
        description: "Arbitrum: Dfyn",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/42161/0xaedE1EFe768bD8A1663A7608c63290C60B85e71c.svg",
        border_color: "#d14a8a",
        start_gradient_color: "#258bbf",
        end_gradient_color: "#921950",
        display_name: "Dfyn",
      },
      {
        id: "65",
        chain_id: 42161,
        name: "swapr",
        description: "Arbitrum: Swapr",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/42161/0x530476d5583724A89c8841eB6Da76E7Af4C0F17E.svg",
        border_color: "#b43bff",
        start_gradient_color: "#3bffff",
        end_gradient_color: "#330052",
        display_name: "Swapr",
      },
      {
        id: "81",
        chain_id: 42161,
        name: "curve",
        description: "Arbitrum: Curve",
        type: "curve",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-curve.svg",
        border_color: "#ff3f29",
        start_gradient_color: "#faff00",
        end_gradient_color: "#002fff",
        display_name: "Curve",
      },
      {
        id: "89",
        chain_id: 42161,
        name: "kyber",
        description: "Arbitrum: Kyber",
        type: "dmm",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#49bc98",
        start_gradient_color: "#e3bbe6",
        end_gradient_color: "#b549bc",
        display_name: "KyberSwap Classic",
      },
      {
        id: "95",
        chain_id: 42161,
        name: "dodo",
        description: "Arbitrum: DODO",
        type: "dodo",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-dodo.svg",
        border_color: "#06c9ff",
        start_gradient_color: "#b906ff",
        end_gradient_color: "#ff7b06",
        display_name: "DODO",
      },
      {
        id: "103",
        chain_id: 42161,
        name: "fraxswap",
        description: "Arbitrum: Fraxswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-fraxswap.svg",
        border_color: "#a8a8a8",
        start_gradient_color: "#464646",
        end_gradient_color: "#0d0d0d",
        display_name: "Fraxswap",
      },
      {
        id: "127",
        chain_id: 42161,
        name: "saddle",
        description: "Arbitrum: Saddle",
        type: "saddle",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/saddle_finance.svg",
        border_color: "#9d70ff",
        start_gradient_color: "#4b11f2",
        end_gradient_color: "#a286e0",
        display_name: "Saddle",
      },
      {
        id: "140",
        chain_id: 42161,
        name: "balancer",
        description: "Arbitrum: Balancer",
        type: "balancer",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/balancer-amm.svg",
        border_color: "#4c4b9b",
        start_gradient_color: "#648ff8",
        end_gradient_color: "#191b22",
        display_name: "Balancer",
      },
      {
        id: "156",
        chain_id: 42161,
        name: "kyberswap_elastic",
        description: "Arbitrum: KyberSwap Elastic",
        type: "kyberswap_elastic",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#24b2bc",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "KyberSwap Elastic",
      },
    ],
    most_liquid_tokens: [
      {
        id: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1-arbitrum",
        address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
        name: "Wrapped Ether",
        symbols: ["WETH"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9-arbitrum",
        address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
        name: "Tether USD",
        symbols: ["USDT"],
        logoURI: [],
        decimals: 6,
      },
      {
        id: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8-arbitrum",
        address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
        name: "USD Coin (Arb1)",
        symbols: ["USDC"],
        logoURI: [],
        decimals: 6,
      },
      {
        id: "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f-arbitrum",
        address: "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
        name: "Wrapped Bitcoin",
        symbols: ["WBTC"],
        logoURI: [],
        decimals: 8,
      },
    ],
    native_token: {
      id: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1-arbitrum",
      address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
      name: "Wrapped Ether",
      symbols: ["WETH"],
      logoURI: [],
      decimals: 18,
    },
    block_explorer: {
      url: "https://arbiscan.io",
      logo_uri: "https://assets-stage.dex.guru/icons/explorer-42161.svg",
      display_name: "Arbiscan",
      token_path: "/token",
      address_path: "/address",
    },
    zerox_api: {
      url: "https://api.1inch.io",
      wrapper_url: "https://api-trading.dex.guru/arbitrum",
      gas_url: "https://api-trading.dex.guru/arbitrum/gas_prices",
      wrapper_gas_url: "https://api-trading.dex.guru/arbitrum/gas_prices",
      spender_address: "0x1111111254fb6c44bac0bed2854e76f90643097d",
    },
    one_inch_api: {
      url: "https://limit-orders.1inch.io/v2.0/42161/limit-order",
      wrapper_url: "https://api-trading.dex.guru/arbitrum/limit",
      gas_url: "https://api-trading.dex.guru/arbitrum/gas_prices",
      wrapper_gas_url: "https://api-trading.dex.guru/arbitrum/gas_prices",
      spender_address: "0x7f069df72b7a39bce9806e3afaf579e54d8cf2b9",
    },
    market_order: {
      url: "https://api-trading.dex.guru/market/arbitrum",
      spender_addresses: [
        {
          name: "1inch",
          address: "0x1111111254eeb25477b68fb85ed929f73a960582",
          provider: "one_inch",
        },
        {
          name: "ParaSwap",
          address: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
          provider: "paraswap",
        },
      ],
    },
    limit_order: {
      url: "https://api-trading.dex.guru/limit/arbitrum",
      spender_addresses: [
        {
          name: "1inch",
          address: "0x7f069df72b7a39bce9806e3afaf579e54d8cf2b9",
          provider: "one_inch",
        },
      ],
    },
    gas_url: "https://api-trading.dex.guru/gas_prices/arbitrum",
  },
  {
    id: 42220,
    name: "celo",
    description: "Celo",
    type: "evm",
    logo_uri: "https://assets-stage.dex.guru/icons/chain-42220.svg",
    enabled: true,
    rpc_url: "https://forno.celo.org",
    gas_buffer: 100000000000000000,
    balances_wss_request: null,
    color: "#3ecf82",
    primary_token_address: "0x471ece3750da237f93b8e339c536989b8978a438",
    secondary_token_address: "0x765DE816845861e75A25fCA122bb6898B8B1282a",
    amms: [
      {
        id: "9",
        chain_id: 42220,
        name: "ubeswap",
        description: "Ubeswap Moola",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/42220/0xE3D8bd6Aed4F159bc8000a9cD47CffDb95F96121.svg",
        border_color: "#8878c3",
        start_gradient_color: "rgba(135, 117, 201, 0.6)",
        end_gradient_color: "rgba(32, 51, 53, 0.6)",
        display_name: "Ubeswap",
      },
      {
        id: "63",
        chain_id: 42220,
        name: "sushiswap",
        description: "Celo: SushiSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/42161/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506.png",
        border_color: "#f371f5",
        start_gradient_color: "rgba(17, 55, 114, 0.6)",
        end_gradient_color: "rgba(17, 55, 114, 0.6)",
        display_name: "SushiSwap",
      },
      {
        id: "99",
        chain_id: 42220,
        name: "uniswap_v3",
        description: "Celo: Uniswap V3",
        type: "uniswap_v3",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets.dex.guru/icons/amm-uniswap_v3.png",
        border_color: "#00aeb9",
        start_gradient_color: "#024764",
        end_gradient_color: "#38a062",
        display_name: "Uniswap",
      },
    ],
    most_liquid_tokens: [
      {
        id: "0x471ece3750da237f93b8e339c536989b8978a438-celo",
        address: "0x471ece3750da237f93b8e339c536989b8978a438",
        name: "Celo native asset",
        symbols: ["CELO"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x64defa3544c695db8c535d289d843a189aa26b98-celo",
        address: "0x64defa3544c695db8c535d289d843a189aa26b98",
        name: "Moola cUSD AToken",
        symbols: ["mCUSD"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x00be915b9dcf56a3cbe739d9b9c202ca692409ec-celo",
        address: "0x00be915b9dcf56a3cbe739d9b9c202ca692409ec",
        name: "Ubeswap",
        symbols: ["UBE"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0xa8d0e6799ff3fd19c6459bf02689ae09c4d78ba7-celo",
        address: "0xa8d0e6799ff3fd19c6459bf02689ae09c4d78ba7",
        name: "Moola cEUR MToken",
        symbols: ["mCEUR"],
        logoURI: [],
        decimals: 18,
      },
    ],
    native_token: {
      id: "0x471ece3750da237f93b8e339c536989b8978a438-celo",
      address: "0x471ece3750da237f93b8e339c536989b8978a438",
      name: "Celo native asset",
      symbols: ["CELO"],
      logoURI: [],
      decimals: 18,
    },
    block_explorer: {
      url: "https://celoscan.io",
      logo_uri: "https://assets-stage.dex.guru/icons/explorer-42220.svg",
      display_name: "Celo Explorer",
      token_path: "/tokens",
      address_path: "/address",
    },
    zerox_api: {
      url: "https://celo.api.0x.org",
      wrapper_url: "https://api-trading.dex.guru/celo",
      gas_url: "https://gas.celo.api.0x.org",
      wrapper_gas_url: "https://api-trading.dex.guru/celo/gas_prices",
      spender_address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
    },
    one_inch_api: {
      url: null,
      wrapper_url: null,
      gas_url: null,
      wrapper_gas_url: null,
      spender_address: null,
    },
    market_order: {
      url: "https://api-trading.dex.guru/market/celo",
      spender_addresses: [
        {
          name: "0x",
          address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
          provider: "zero_x",
        },
      ],
    },
    limit_order: { url: null, spender_addresses: [] },
    gas_url: "https://api-trading.dex.guru/gas_prices/celo",
  },
  {
    id: 43114,
    name: "avalanche",
    description: "Avalanche",
    type: "evm",
    logo_uri: "https://assets-stage.dex.guru/icons/chain-43114.svg",
    enabled: true,
    rpc_url: "https://api.avax.network/ext/bc/C/rpc",
    gas_buffer: 100000000000000000,
    balances_wss_request: null,
    color: "#f55c5d",
    primary_token_address: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
    secondary_token_address: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    amms: [
      {
        id: "6",
        chain_id: 43114,
        name: "pangolin",
        description: "Avalanche: Pangolin",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/43114/0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106.svg",
        border_color: "#ff6b00",
        start_gradient_color: "rgba(92, 32, 120, 0.6)",
        end_gradient_color: "rgba(202, 128, 40, 0.6)",
        display_name: "Pangolin",
      },
      {
        id: "7",
        chain_id: 43114,
        name: "traderjoe",
        description: "Avalanche: Traderjoe-XYZ",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/43114/0x60aE616a2155Ee3d9A68541Ba4544862310933d4.svg",
        border_color: "#ff746c",
        start_gradient_color: "rgba(40, 69, 90, 0.6)",
        end_gradient_color: "rgba(198, 103, 97, 0.6)",
        display_name: "Traderjoe",
      },
      {
        id: "15",
        chain_id: 43114,
        name: "lydia",
        description: "Avalanche: Lydia Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/43114/0xa52abe4676dbfd04df42ef7755f01a3c41f28d27.svg",
        border_color: "#ffe054",
        start_gradient_color: "rgba(140, 88, 248, 0.6)",
        end_gradient_color: "rgba(56, 39, 158, 0.6)",
        display_name: "Lydia Finance",
      },
      {
        id: "19",
        chain_id: 43114,
        name: "elk_finance",
        description: "Avalanche: Elk Finance",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/43114/0x9E4AAbd2B3E60Ee1322E94307d0776F2c8e6CFbb.svg",
        border_color: "#00bb64",
        start_gradient_color: "rgba(0, 93, 202, 0.6)",
        end_gradient_color: "rgba(0, 71, 38, 0.6)",
        display_name: "Elk FInance",
      },
      {
        id: "20",
        chain_id: 43114,
        name: "canary",
        description: "Avalanche: Canary",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/43114/0x06f8ED60393AC6A4B16900273C9313222dfe9940.svg",
        border_color: "#ffe600",
        start_gradient_color: "rgba(113, 93, 23, 0.6)",
        end_gradient_color: "rgba(0, 0, 0, 0.6)",
        display_name: "Canary Exchange",
      },
      {
        id: "23",
        chain_id: 43114,
        name: "empiredex",
        description: "Avalanche: EmpireDEX",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/43114/0xdADaae6cDFE4FA3c35d54811087b3bC3Cd60F348.svg",
        border_color: "#ff9900",
        start_gradient_color: "#68695d",
        end_gradient_color: "#282b28",
        display_name: "EmpireDEX",
      },
      {
        id: "24",
        chain_id: 43114,
        name: "yetiswap",
        description: "Avalanche: YetiSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/43114/0x262DcFB36766C88E6A7a2953c16F8defc40c378A.svg",
        border_color: "#a0dcff",
        start_gradient_color: "#004f7d",
        end_gradient_color: "#001633",
        display_name: "YetiSwap",
      },
      {
        id: "25",
        chain_id: 43114,
        name: "baguette",
        description: "Avalanche: Baguette",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/43114/0xF7b1e993d1b4F7348D64Aa55A294E4B74512F7f2.svg",
        border_color: "#eabf78",
        start_gradient_color: "#8d7248",
        end_gradient_color: "#0f0e1a",
        display_name: "Baguette",
      },
      {
        id: "64",
        chain_id: 43114,
        name: "sushiswap",
        description: "Avalanche: SushiSwap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri:
          "https://assets-stage.dex.guru/icons/42161/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506.png",
        border_color: "#f371f5",
        start_gradient_color: "rgba(17, 55, 114, 0.6)",
        end_gradient_color: "rgba(17, 55, 114, 0.6)",
        display_name: "SushiSwap",
      },
      {
        id: "82",
        chain_id: 43114,
        name: "curve",
        description: "Avalanche: Curve",
        type: "curve",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-curve.svg",
        border_color: "#ff3f29",
        start_gradient_color: "#faff00",
        end_gradient_color: "#002fff",
        display_name: "Curve",
      },
      {
        id: "88",
        chain_id: 43114,
        name: "kyber",
        description: "Avalanche: Kyber",
        type: "dmm",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#49bc98",
        start_gradient_color: "#e3bbe6",
        end_gradient_color: "#b549bc",
        display_name: "KyberSwap Classic",
      },
      {
        id: "104",
        chain_id: 43114,
        name: "fraxswap",
        description: "Avalanche: Fraxswap",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-fraxswap.svg",
        border_color: "#a8a8a8",
        start_gradient_color: "#464646",
        end_gradient_color: "#0d0d0d",
        display_name: "Fraxswap",
      },
      {
        id: "118",
        chain_id: 43114,
        name: "swapsicle",
        description: "Avalanche: Swapsicle",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-swapsicle.svg",
        border_color: "#895ddb",
        start_gradient_color: "#a8e3cb",
        end_gradient_color: "#da8dc1",
        display_name: "Swapsicle",
      },
      {
        id: "134",
        chain_id: 43114,
        name: "platypus",
        description: "Avalanche: Platypus",
        type: "platypus",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/platypus-amm.svg",
        border_color: "#00c83a",
        start_gradient_color: "#0384a5",
        end_gradient_color: "#000000",
        display_name: "Platypus",
      },
      {
        id: "145",
        chain_id: 43114,
        name: "radioshack",
        description: "Avalanche: RadioShack",
        type: "uniswap_v2",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-radioshack.svg",
        border_color: "#ff0000",
        start_gradient_color: "#0d0314",
        end_gradient_color: "#370a14",
        display_name: "RadioShack",
      },
      {
        id: "154",
        chain_id: 43114,
        name: "kyberswap_elastic",
        description: "Avalanche: KyberSwap Elastic",
        type: "kyberswap_elastic",
        enabled: true,
        last_pair_index: 0,
        logo_uri: "https://assets-stage.dex.guru/icons/amm-kyber.png",
        border_color: "#24b2bc",
        start_gradient_color: "rgba(2, 71, 100, 0.6)",
        end_gradient_color: "rgba(56, 160, 98, 0.6)",
        display_name: "KyberSwap Elastic",
      },
    ],
    most_liquid_tokens: [
      {
        id: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7-avalanche",
        address: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
        name: "Wrapped AVAX",
        symbols: ["WAVAX"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x60781c2586d68229fde47564546784ab3faca982-avalanche",
        address: "0x60781c2586d68229fde47564546784ab3faca982",
        name: "Pangolin",
        symbols: ["PNG"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab-avalanche",
        address: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
        name: "Wrapped Ether",
        symbols: ["WETH.e"],
        logoURI: [],
        decimals: 18,
      },
      {
        id: "0xc7198437980c041c805a1edcba50c1ce5db95118-avalanche",
        address: "0xc7198437980c041c805a1edcba50c1ce5db95118",
        name: "Tether USD",
        symbols: ["USDT.e"],
        logoURI: [],
        decimals: 6,
      },
    ],
    native_token: {
      id: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7-avalanche",
      address: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
      name: "Wrapped AVAX",
      symbols: ["WAVAX"],
      logoURI: [],
      decimals: 18,
    },
    block_explorer: {
      url: "https://snowtrace.io",
      logo_uri: "https://assets-stage.dex.guru/icons/explorer-43114.svg",
      display_name: "Snowtrace",
      token_path: "/token",
      address_path: "/address",
    },
    zerox_api: {
      url: "https://avalanche.api.0x.org",
      wrapper_url: "https://api-trading.dex.guru/avalanche",
      gas_url: "https://gas.avalanche.api.0x.org",
      wrapper_gas_url: "https://api-trading.dex.guru/avalanche/gas_prices",
      spender_address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
    },
    one_inch_api: {
      url: "https://limit-orders.1inch.io/v2.0/43114/limit-order",
      wrapper_url: "https://api-trading.dex.guru/avalanche/limit",
      gas_url: "https://api-trading.dex.guru/avalanche/gas_prices",
      wrapper_gas_url: "https://api-trading.dex.guru/avalanche/gas_prices",
      spender_address: "0x0f85a912448279111694f4ba4f85dc641c54b594",
    },
    market_order: {
      url: "https://api-trading.dex.guru/market/avalanche",
      spender_addresses: [
        {
          name: "0x",
          address: "0xdef1c0ded9bec7f1a1670819833240f027b25eff",
          provider: "zero_x",
        },
        {
          name: "1inch",
          address: "0x1111111254eeb25477b68fb85ed929f73a960582",
          provider: "one_inch",
        },
        {
          name: "ParaSwap",
          address: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
          provider: "paraswap",
        },
      ],
    },
    limit_order: {
      url: "https://api-trading.dex.guru/limit/avalanche",
      spender_addresses: [
        {
          name: "1inch",
          address: "0x0f85a912448279111694f4ba4f85dc641c54b594",
          provider: "one_inch",
        },
      ],
    },
    gas_url: "https://api-trading.dex.guru/gas_prices/avalanche",
  },
];

export default config;
