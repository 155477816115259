import { FC } from 'react'

import { GuruImage } from '../icons'

export const ProviderMenuWelcome: FC = () => {
  return (
    <div className="provider-menu__welcome">
      <div className="provider-menu-welcome">
        <div className="provider-menu-welcome__image">
          <GuruImage />
        </div>
        <h2 className="provider-menu-welcome__title">This will allow DexGuru to:</h2>
        <div className="provider-menu-welcome__body">
          <ul className="provider-menu__features">
            <li className="provider-menu__feature pro">
              See your wallet address and tokens balances
            </li>
            <li className="provider-menu__feature pro">
              Sync app settings and your favorites list across devices
            </li>
            <li className="provider-menu__feature con">Buy you a nice seafood dinner</li>
          </ul>
        </div>
        <div className="provider-menu-welcome__footer">
          DexGuru is a fully non-custodial platform, so the assets in your wallet are always under
          your control.
        </div>
      </div>
    </div>
  )
}

export default ProviderMenuWelcome
