import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import * as auth from "../../services/authService";
import { AbstractConnector } from "@web3-react/abstract-connector";
import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import { NetworksConfig } from "../../supportedChainsConfig";

import { setCurrentProvider } from "../currentProvider";
import { isSuppressEagerConnect } from "../eagerConnect";
import { useEagerConnect, useInactiveListener } from "../hooks";
import { CloseIcon } from "../icons";
import { Logo, ProviderName } from "../types";
import { isAuthenticated } from "../utils";
import WalletConnectors from "../WalletConnectors";
import ProviderMenuCurrent from "./ProviderMenuCurrent";
import ProviderMenuList from "./ProviderMenuList";
import ProviderMenuStep from "./ProviderMenuStep";
import ProviderMenuWelcome from "./ProviderMenuWelcome";
import { Address, TokenV3 } from "../../models/model";

import "../scss/_provider-menu.scss";

const SIGN_INTERVAL = 1000;

enum ProviderMenuState {
  PROVIDERS_LIST = "select",
  SIGN_MESSAGE = "progress",
  CONNECTED = "connected",
}

interface ProviderMenuProps {
  closeProviderMenu: () => void;
  currentToken?: TokenV3;
  isMobile: boolean;
  onTokenReceived?: (
    currentToken: TokenV3,
    account: Address | null | undefined
  ) => void;
  onDisconnect: (
    account: Address,
    currentProviderName: ProviderName
  ) => Promise<void>;
  onConnect?: (account: Address | null) => Promise<void>;
  onError?: (error: Error) => Promise<void>;
  forceDisconnect?: boolean;
  closeAfterSelect?: boolean;
  networksConfig: NetworksConfig[];
  signatureStepContent?: React.ReactNode;
  walletConnectors?: WalletConnectors;
  providerLogos: Record<ProviderName, Logo>;
}

let interval: number | null = null;

export function ProviderMenu({
  closeAfterSelect = true,
  ...props
}: ProviderMenuProps): React.ReactElement {
  const {
    connector,
    activate,
    deactivate,
    active,
    error: web3Error,
    account,
    chainId,
  } = useWeb3React();
  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] =
    useState<AbstractConnector>();
  const [activeProvider, setActiveProvider] = useState<ProviderName>();
  const [error, setError] = useState<Error>();
  const [step, setStep] = useState<ProviderMenuState>(
    ProviderMenuState.PROVIDERS_LIST
  );

  useEffect(() => {
    const lsHandler = (): void => {
      const isSignAwaiting = auth.isAwaitingSignature();
      if (isSignAwaiting) {
        setStep(ProviderMenuState.SIGN_MESSAGE);
      }
    };

    window.addEventListener("storage", lsHandler);

    return (): void => {
      interval && clearInterval(interval);
      window.removeEventListener("storage", lsHandler);
    };
  }, []);

  useEffect(() => {
    if (props.currentToken && props.onTokenReceived) {
      props.onTokenReceived(props.currentToken, account);
    }
    if (!connector) {
      return;
    }
    connector.on("close", () => {
      /*do nothing*/
    });

    props.walletConnectors
      ?.getProviderName(connector)
      .then(
        (providerName) =>
          connector && providerName && setActiveProvider(providerName)
      );
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatingConnector, connector, account, props.currentToken?.id]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect(
    isSuppressEagerConnect(),
    props.walletConnectors
  );

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(
    !triedEager || !!activatingConnector,
    props.walletConnectors
  );

  const connectHandler = async (provider: ProviderName): Promise<void> => {
    setCurrentProvider(provider);
    setActiveProvider(provider);

    if (isAuthenticated() && account && active) {
      setStep(ProviderMenuState.CONNECTED);
    } else {
      setStep(ProviderMenuState.SIGN_MESSAGE);

      interval = setInterval(() => {
        if (isAuthenticated() && account && active) {
          setStep(ProviderMenuState.CONNECTED);
          interval && clearInterval(interval);
        }
      }, SIGN_INTERVAL) as unknown as number;
    }

    const currentConnector: AbstractConnector | undefined =
      props.walletConnectors?.getProviderConnector(provider);
    const currentProviderName: ProviderName | undefined =
      await props.walletConnectors?.getProviderName(connector);

    if (
      connector &&
      account &&
      active &&
      currentProviderName &&
      currentProviderName !== provider
    ) {
      await props.onDisconnect(account, currentProviderName);
    }

    setActivatingConnector(currentConnector);

    if (currentConnector) {
      await props.walletConnectors
        ?.connect(currentConnector, activate, (error: Error) => {
          props.onError && props.onError(error);
          setError(error);
        })
        .then(async () => {
          const account = await currentConnector.getAccount();

          if (
            props.onConnect &&
            typeof props.onConnect === "function" &&
            account
          ) {
            await props.onConnect(account);
          }
        });
    }

    closeAfterSelect && props.closeProviderMenu();
  };

  const disconnectHandler = async (): Promise<void> => {
    props.walletConnectors?.disconnectProvider(
      account,
      connector,
      deactivate,
      props.onDisconnect
    );

    setActivatingConnector(undefined);
    setStep(ProviderMenuState.PROVIDERS_LIST);
    setError(undefined);
  };

  useEffect(() => {
    if (props.forceDisconnect && step !== ProviderMenuState.SIGN_MESSAGE) {
      disconnectHandler();
    }
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forceDisconnect]);

  useEffect(() => {
    if (web3Error instanceof UnsupportedChainIdError) {
      return setError(web3Error);
    }

    return setError(web3Error);
  }, [chainId, web3Error]);

  return (
    <div
      className={classNames(
        "provider-menu",
        { "provider-menu--welcome": step === ProviderMenuState.PROVIDERS_LIST },
        { "provider-menu--error": !!error }
      )}
    >
      {!props.isMobile && props.closeProviderMenu && (
        <button
          className="provider-menu__close"
          onClick={props.closeProviderMenu}
        >
          <span className="icon">
            <CloseIcon />
          </span>
        </button>
      )}

      {step === ProviderMenuState.PROVIDERS_LIST && (
        <>
          <ProviderMenuWelcome />
          <ProviderMenuStep
            caption={
              <>
                <span className="part">Get Started</span>
                <span className="part">With Web3 Wallet</span>
              </>
            }
            error={error}
          >
            <ProviderMenuList
              isMobile={props.isMobile}
              onConnect={connectHandler}
              connectors={props.walletConnectors}
              providerLogos={props.providerLogos}
            />
          </ProviderMenuStep>
        </>
      )}
      {step !== ProviderMenuState.PROVIDERS_LIST && (
        <ProviderMenuStep
          caption="Connecting..."
          error={error}
          onDisconnect={disconnectHandler}
        >
          <ProviderMenuCurrent
            providerLogo={activeProvider && props.providerLogos[activeProvider]}
            providerName={activeProvider}
            content={props.signatureStepContent}
            isLoading={!error}
          />
        </ProviderMenuStep>
      )}
    </div>
  );
}

export default ProviderMenu;
