import Web3 from 'web3'

const SIGN_AWAIT_KEY = 'signAwait'

export const isAwaitingSignature = (): boolean =>
  Boolean(window.localStorage.getItem(SIGN_AWAIT_KEY))

export const web3Sign = async (nonce: string, wallet?: string, library?: Web3): Promise<string> => {
  try {
    if (library && wallet) {
      window.localStorage.setItem(SIGN_AWAIT_KEY, 'true')

      const signature = await library.eth.personal.sign(nonce, wallet, '')
      if (!signature) {
        throw new Error('signature error')
      }
      return signature
    }

    throw new Error('signature error')
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('signature error', error)
    throw error
  } finally {
    window.localStorage.removeItem(SIGN_AWAIT_KEY)
  }
}
