import jazzicon from "@metamask/jazzicon";
import React, { useEffect, useRef } from "react";
import { Address } from "../../models/model";

interface IWalletAvatar {
  account?: Address | null;
  classNamePrefix: string;
  isPaid?: boolean;
  onClick?: () => void;
}

const ICON_SIZE = 24;

const WalletAvatar: React.FC<IWalletAvatar> = ({
  account,
  classNamePrefix,
  isPaid,
  onClick,
}) => {
  const avatarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = avatarRef.current;

    if (!element) {
      return;
    }

    if (element.firstChild) {
      element.removeChild(element.firstChild);
    }

    if (account) {
      const addr = account.slice(2, 10);
      const seed = parseInt(addr, 16);
      const icon = jazzicon(ICON_SIZE, seed);
      const background = icon.style.getPropertyValue("background");
      icon.removeAttribute("style");
      icon.setAttribute("style", `background:${background}`);
      icon.className = `${classNamePrefix}__avatar-image`;
      element.appendChild(icon);
    }
  }, [account, avatarRef, classNamePrefix]);

  return (
    <div className={`${classNamePrefix}__avicon`}>
      {isPaid && (
        <span className={`${classNamePrefix}__indicator`}>
          <span className="caption">PRO</span>
        </span>
      )}
      <div
        onClick={onClick}
        className={`${classNamePrefix}__avatar`}
        ref={avatarRef}
      />
    </div>
  );
};

export default WalletAvatar;
