import { UnsupportedChainIdError } from "@web3-react/core";
import * as auth from "../services/authService";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { Address } from "../models/model";

export const getErrorMessage = (error: Error): string => {
  if (error instanceof NoEthereumProviderError) {
    return "Looks like you do not have the MetaMask wallet extension. Please download it and try again";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You are trying to connect with an unsupported network. Please change the network and try again.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else if (
    error.message?.includes("Already processing eth_requestAccounts")
  ) {
    return "You should unlock your wallet provider first. Pending eth_requestAccounts request.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
};

export const isAuthenticated = (): boolean =>
  !!auth.getAccountId() && !!auth.getAccessToken();

export const getAccountNumber = (account: Address): number =>
  parseInt(account.slice(2, 10), 16);
