import { UnsupportedChainIdError } from "@web3-react/core";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";
import React, { createContext, FC, useEffect, useState } from "react";
import Web3 from "web3";
import { getAccessToken, getAccountId } from "../services/authService";

import { setSuppressEagerConnection } from "./eagerConnect";
import WalletConnectors from "./WalletConnectors";

const REFRESH_TOKEN_INTERVAL = 43200000;

interface AuthResponse {
  error: string | null;
  accessToken: string | null;
  refreshToken: string | null;
}

export interface IAuthContext {
  forceDisconnect: boolean;
  setForceDisconnect: (vale: boolean) => unknown;

  closeProviderMenu: () => void;
  openProviderMenu: (callback?: () => Promise<void>) => void;
  onConnect?: (account: string | null) => Promise<void>;
  isAuth: boolean;
  accountId: string | null;
}

const noop = (): void => {
  return undefined;
};
const asyncNoop = async (): Promise<void> => {
  return undefined;
};

export const AuthContext = createContext<IAuthContext>({
  forceDisconnect: false,
  setForceDisconnect: (v) => v,
  closeProviderMenu: noop,
  openProviderMenu: noop,
  onConnect: asyncNoop,
  isAuth: false,
  accountId: null,
});

const AuthWrapper: FC<{
  sdkCheckAuth: (
    instance: string,
    wallet: string,
    library?: Web3,
    onSuccess?: () => void,
    onFail?: () => void
  ) => Promise<void>;
  sdkHasToken: () => boolean;
  sdkRefreshToken: (instance: string) => Promise<AuthResponse>;
  web3React: Web3ReactContextInterface;
  dexGuruAPIV2Url: string;
  dexGuruAPIV3Url: string;
  onWalletConnectionError?: (error: Error) => Promise<void>;
  onWalletConnect?: (account: string | null) => Promise<void>;
  children: React.ReactElement | React.ReactElement[];
  onOpenProviderMenu: () => void;
  onCloseProviderMenu: () => void;
  isOpenProviderMenu: boolean;
  walletConnectors?: WalletConnectors;
}> = ({
  sdkCheckAuth,
  sdkHasToken,
  sdkRefreshToken,
  web3React,
  dexGuruAPIV2Url,
  dexGuruAPIV3Url,
  onWalletConnectionError,
  onWalletConnect,
  children,
  onOpenProviderMenu,
  onCloseProviderMenu,
  isOpenProviderMenu,
  walletConnectors,
}) => {
  const {
    account,
    connector,
    deactivate,
    activate,
    library,
    chainId,
    error: web3Error,
  } = web3React;
  const [forceDisconnect, setForceDisconnect] = useState(false);
  const [accountId, setAccountId] = useState<string | null>(null);

  useEffect(() => {
    if (web3Error instanceof UnsupportedChainIdError) {
      walletConnectors?.disconnect(connector, deactivate);
    }
  }, [chainId, connector, deactivate, walletConnectors, web3Error]);

  useEffect(() => {
    const accessToken = getAccessToken();
    const accountId = getAccountId();
    setAccountId(accountId);
    if (!accessToken && accountId) {
      setSuppressEagerConnection(false);
    }

    const interval = setInterval(() => {
      refreshToken();
    }, REFRESH_TOKEN_INTERVAL || 43200000);
    return (): void => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!account) {
      setAccountId(null);
      setForceDisconnect(false);
    } else {
      if (walletConnectors) {
        checkAuth(account, walletConnectors);
      }
    }
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, walletConnectors]);

  const checkAuth = async (
    account: string,
    walletConnectors: WalletConnectors
  ): Promise<void> => {
    await sdkCheckAuth(
      dexGuruAPIV3Url,
      account,
      library,
      () => {
        if (connector) {
          walletConnectors
            .connect(connector, activate, (error): void => {
              console.error(error);
              onWalletConnectionError && onWalletConnectionError(error);
            })
            .then(async () => {
              const account = await connector.getAccount();
              onWalletConnect && (await onWalletConnect(account));
            });
        }
        setSuppressEagerConnection(false);
        setAccountId(getAccountId());
      },
      () => {
        setSuppressEagerConnection(false);
        setForceDisconnect(true);
        setAccountId(null);
      }
    );
    isOpenProviderMenu && closeProviderMenu();
  };

  const refreshToken = async (): Promise<void> => {
    if (!sdkHasToken()) {
      return;
    }

    const { error } = await sdkRefreshToken(dexGuruAPIV2Url);

    if (error) {
      setForceDisconnect(false);
      // TODO, how do we inform the user?
      console.error("Unable to refresh session, you are logged out");
    }
  };

  const openProviderMenu = (
    callback: () => Promise<void> = asyncNoop
  ): void => {
    onOpenProviderMenu();
    setForceDisconnect && setForceDisconnect(false);
  };

  const closeProviderMenu = (): void => {
    const hasAccess = getAccessToken();
    if (!hasAccess) {
      walletConnectors?.disconnect(connector, deactivate);
    }

    onCloseProviderMenu();
  };

  return (
    <AuthContext.Provider
      value={{
        forceDisconnect,
        setForceDisconnect,
        openProviderMenu,
        closeProviderMenu,
        isAuth: !!accountId,
        onConnect: async (account): Promise<void> => {
          setAccountId(getAccountId());
          onWalletConnect && onWalletConnect(account);
        },
        accountId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthWrapper;
