import React, { FC, ReactNode } from 'react'

import { InfinityAnimation } from '../icons'
import { Logo, ProviderName } from '../types'

export const ProviderMenuCurrent: FC<{
  providerLogo?: Logo
  providerName?: ProviderName
  content?: ReactNode
  isLoading?: boolean
}> = ({ providerLogo, content, isLoading, providerName }) => {
  const Logo = providerLogo ? providerLogo : (): null => null

  const defaultSignatureMessage = (providerName?: ProviderName): string =>
    `Accept the signature request in your ${providerName || ''} wallet`

  return (
    <div className="provider-menu-progress">
      <div className="provider-menu-progress__icon">
        <span className="icon">
          <Logo />
        </span>
      </div>
      <div className="provider-menu-progress__request">
        {content || defaultSignatureMessage(providerName)}
      </div>
      {isLoading && (
        <div className="provider-menu-progress__indicator">{<InfinityAnimation />}</div>
      )}
    </div>
  )
}

export default ProviderMenuCurrent
