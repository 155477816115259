import React, { FC } from "react";
import { WalletConnectors } from "../components";
import ProviderMenu from "../components/ProviderMenu";

import { providerLogos } from "../config/providerLogos";
import { NetworksConfig } from "../supportedChainsConfig";
import { useAuth } from "./AuthWrapperWithContext";

interface IProviderMenuWrapper {
  onDisconnect: (account: string, currentProviderName: string) => Promise<void>;
  onError?: (error: Error) => Promise<void>;
  closeAfterSelect?: boolean;
  networksConfig: NetworksConfig[];
  walletConnectors: WalletConnectors;
}

const signatureStepContent: React.ReactElement = (
  <>
    <div className="caption">
      Welcome! For full access to DexGuru, log in by accepting the signature
      request to confirm you are the owner of this wallet.
    </div>
    <div className="comment">
      Signing does not create any transactions or approvals.
    </div>
  </>
);

const ProviderMenuWrapper: FC<IProviderMenuWrapper> = (props) => {
  const { forceDisconnect, closeProviderMenu } = useAuth();

  return (
    <ProviderMenu
      providerLogos={providerLogos}
      walletConnectors={props.walletConnectors}
      networksConfig={props.networksConfig}
      forceDisconnect={forceDisconnect}
      closeAfterSelect={false}
      closeProviderMenu={closeProviderMenu}
      isMobile={false}
      onDisconnect={props.onDisconnect}
      signatureStepContent={signatureStepContent}
    />
  );
};

export default ProviderMenuWrapper;
