import { FC, ReactElement, ReactNode } from 'react'

import { getErrorMessage } from '../utils'
import ProviderMenuDisclaimer from './ProviderMenuDisclaimer'

export const ProviderMenuStep: FC<{
  caption: ReactNode
  error?: Error
  onDisconnect?: () => void
  children?: ReactElement | string | undefined
}> = ({ caption, error, onDisconnect, children }) => {
  return (
    <div className="provider-menu__step">
      <div className="provider-menu-step">
        <div className="provider-menu-step__header">
          <h3 className="provider-menu-step__title">{caption}</h3>
        </div>
        <div className="provider-menu-step__body">
          {children}
          {!!error && <div className="provider-menu__error">{getErrorMessage(error)}</div>}
        </div>
        <div className="provider-menu-step__footer">
          {onDisconnect && (
            <button className="provider-menu__disconnect" onClick={onDisconnect}>
              <span className="caption">Disconnect</span>
            </button>
          )}
          {!onDisconnect && <ProviderMenuDisclaimer />}
        </div>
      </div>
    </div>
  )
}

export default ProviderMenuStep
