import { useWeb3React } from "@web3-react/core";
import React, { FC, Suspense } from "react";
import { Address } from "../../models/model";
import { NetworksConfig } from "../../supportedChainsConfig";

import { ProviderName } from "../types";
import WalletConnectors from "../WalletConnectors";

const WalletButton = React.lazy(() => import("./WalletButton"));

const WalletAccountButton = React.lazy(() => import("./WalletAccountButton"));

interface WalletWidgetProps {
  openProviderMenu?: () => void;
  networksConfig: NetworksConfig[];
  isLoading: boolean;
  isMobile?: boolean;
  isPaid?: boolean;
  hashTxn?: string;
  traderProfileURL?: string;
  isOpen?: boolean;
  onDialogToggle?: (state: boolean) => void;
  onClickTraderProfile?: (e: React.MouseEvent) => void;
  onDisconnect?: (
    account: Address,
    currentProviderName: ProviderName
  ) => Promise<void>;
  tooltipWalletConnect?: string;
  tooltipTXPending?: string;
  iconIndicator?: string;
  walletConnectors?: WalletConnectors;
}

export const WalletWidget: FC<WalletWidgetProps> = (props) => {
  const { account } = useWeb3React();

  if (!account) {
    return (
      <Suspense fallback={<></>}>
        <WalletButton {...props} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<></>}>
      <WalletAccountButton {...props} />
    </Suspense>
  );
};

export default WalletWidget;
