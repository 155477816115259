import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { Address } from "../models/model";

import { isSuppressEagerConnect } from "./eagerConnect";
import WalletConnectors from "./WalletConnectors";

export function useEagerConnect(
  suppress = false,
  walletConnectors?: WalletConnectors
): boolean {
  const { activate, active, connector } = useWeb3React();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (suppress) {
      return;
    }

    connect(walletConnectors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  useEffect(() => {
    if (suppress) {
      return;
    }

    if (!active && !connector) {
      connect(walletConnectors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletConnectors]); // intentionally only running on mount (make sure it's only mounted once :))

  const connect = (walletConnectors: WalletConnectors | undefined): void => {
    const injected = walletConnectors?.getInjectedConnector();
    injected?.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  };

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (suppress) {
      return;
    }
    if (!tried && active) {
      setTried(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tried, active]);

  return tried;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export function useInactiveListener(
  suppress = false,
  walletConnectors?: WalletConnectors
): void {
  const { active, error, activate } = useWeb3React();

  useEffect(() => {
    const { ethereum } = window;
    if (
      ethereum?.on &&
      !active &&
      !error &&
      !(suppress || isSuppressEagerConnect())
    ) {
      const injected = walletConnectors?.getInjectedConnector();

      if (!injected) {
        return;
      }

      const handleConnect = (): void => {
        console.log("Handling 'connect' event");
        activate(injected);
      };

      const handleChainChanged = (chainId: number): void => {
        console.log("chainChanged", chainId);
        activate(injected);
      };

      const handleAccountsChanged = (accounts: Address[]): void => {
        console.log("accountsChanged", accounts);
        if (accounts.length > 0) {
          activate(injected);
        }
      };

      const handleNetworkChanged = (networkId: number): void => {
        console.log("networkChanged", networkId);
        activate(injected);
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);

      return (): void => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }

    return (): void => {
      /* do nothing */
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, error, suppress, activate]);
}
