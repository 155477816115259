import jwt from "jsonwebtoken";
import Web3 from "web3";

import {
  loginRequest,
  LoginResponse,
  refreshRequest,
  RefreshResponse,
  revokeToken,
} from "../adapters/apiAuthAdapter";
import { web3Sign } from "../adapters/web3Adapter";
import { AccessToken } from "../models/model";
export interface AuthResponse {
  error: string | null;
  accessToken: string | null;
  refreshToken: string | null;
}

enum LocalStorageKeyEnum {
  account_id = "accountId",
  access_token = "accessToken",
  refresh_token = "refreshToken",
  wallet_id = "walletId",
  test_identifier = "test_identifier",
}

type LocalStorageKey = keyof typeof LocalStorageKeyEnum;

type LocalStorageKeyValue = {
  [val: string]: string | null;
};

const clearLocalStorageAuth = (): void => {
  removeAccessToken();
  removeRefreshToken();
};

const setLocalStorageItems = (items: LocalStorageKeyValue): void => {
  const _items = Object.entries(items).map(
    (item: [string, string | null]): [string, string] => {
      const key = item[0] as LocalStorageKey;
      const value = item[1] || "";
      return [LocalStorageKeyEnum[key], value];
    }
  );

  _items.forEach((item): void => {
    localStorage.setItem(...item);
  });
};

const checkAndStoreTokens = (
  response?: LoginResponse | RefreshResponse
): AuthResponse => {
  if (!response) {
    return {
      error: "Server returned no response",
      accessToken: null,
      refreshToken: null,
    };
  }

  if (response.access_token && response.access_token !== "undefined") {
    localStorage.setItem(
      LocalStorageKeyEnum.access_token,
      response.access_token
    );

    if (response.refresh_token && response.refresh_token !== "undefined") {
      localStorage.setItem(
        LocalStorageKeyEnum.refresh_token,
        response.refresh_token
      );
    }

    return {
      error: null,
      accessToken: response.access_token,
      refreshToken:
        response.refresh_token ||
        localStorage.getItem(LocalStorageKeyEnum.refresh_token),
    };
  }

  return { error: null, accessToken: null, refreshToken: null };
};

export const authenticate = async (
  instance: string,
  wallet: string,
  accountId?: string,
  library?: Web3
): Promise<AuthResponse> => {
  try {
    const response = await loginRequest(
      instance,
      wallet,
      accountId,
      undefined,
      undefined
    );
    const authResponse = checkAndStoreTokens(response);

    if (authResponse.accessToken) {
      return authResponse;
    }

    let signature = "";
    if (!response?.access_token && response?.nonce && wallet) {
      signature = await web3Sign(response.nonce, wallet, library);
      const loginResponse = await loginRequest(
        instance,
        wallet,
        accountId,
        response.nonce,
        signature
      );

      if (!loginResponse) {
        return {
          error: "The server returned no response to login",
          accessToken: null,
          refreshToken: null,
        };
      }

      const { access_token, refresh_token, account_id } = loginResponse;
      setLocalStorageItems({ access_token, refresh_token, account_id });

      return {
        error: null,
        accessToken: access_token,
        refreshToken: refresh_token,
      };
    }

    clearLocalStorageAuth();
    return { error: null, accessToken: null, refreshToken: null };
  } catch (error) {
    clearLocalStorageAuth();
    return { error: error as string, accessToken: null, refreshToken: null };
  }
};

export const dropAuthentication = async (
  instance: string
): Promise<{ error: string | null }> => {
  try {
    const accessToken = localStorage.getItem(LocalStorageKeyEnum.access_token);
    if (accessToken) {
      await revokeToken(instance, accessToken);
    }

    // need to clear after calling apiFetch.
    // The token is needed for the access-revoke call
    clearLocalStorageAuth();
    return { error: null };
  } catch (error) {
    clearLocalStorageAuth();
    return { error: error as string };
  }
};

export const refreshToken = async (instance: string): Promise<AuthResponse> => {
  try {
    const refreshToken =
      localStorage.getItem(LocalStorageKeyEnum.refresh_token) || "";
    const response = await refreshRequest(instance, refreshToken);
    return checkAndStoreTokens(response);
  } catch (error) {
    return { error: error as string, accessToken: null, refreshToken: null };
  }
};

export const checkAuth = async (
  instance: string,
  wallet: string,
  library?: Web3,
  onSuccess?: () => void,
  onFail?: () => void
): Promise<void> => {
  if (!wallet) {
    localStorage.removeItem(LocalStorageKeyEnum.wallet_id);
    return;
  }

  if (wallet) {
    const walletId = localStorage.getItem(LocalStorageKeyEnum.wallet_id);

    if (walletId && walletId !== wallet) {
      localStorage.setItem(LocalStorageKeyEnum.access_token, "");

      onFail && onFail();
    }

    const { isAuth } = await auth(instance, wallet, library);

    if (!isAuth) {
      onFail && onFail();
    } else {
      onSuccess && onSuccess();

      localStorage.setItem(LocalStorageKeyEnum.wallet_id, wallet);
    }
  }
};

export const auth = async (
  instance: string,
  wallet: string,
  library?: Web3
): Promise<{ isAuth: boolean; error: string | null }> => {
  let accessToken = localStorage.getItem(LocalStorageKeyEnum.access_token);
  const accountId = localStorage.getItem(LocalStorageKeyEnum.account_id) || "";

  if (accessToken) {
    const decoded = jwt.decode(accessToken) as { exp: number };
    const now = new Date().valueOf();
    const exp = decoded?.exp || 0;

    if (exp * 1000 > now) {
      return { isAuth: true, error: null };
    }
    localStorage.setItem(LocalStorageKeyEnum.access_token, "");
    accessToken = null;
  }

  if (!accessToken && wallet) {
    const { error, accessToken } = await authenticate(
      instance,
      wallet,
      accountId,
      library
    );

    if (error) {
      return { isAuth: false, error };
    }

    if (accessToken) {
      return { isAuth: true, error };
    }
  }

  return { isAuth: false, error: null };
};

export const hasToken = (): boolean =>
  !!localStorage.getItem(LocalStorageKeyEnum.access_token);

export const getAccountId = (): string | null =>
  window.localStorage.getItem(LocalStorageKeyEnum.account_id);

export const getAccessToken = (): string | null =>
  window.localStorage.getItem(LocalStorageKeyEnum.access_token);

export const getDecodedAccessToken = (): AccessToken | null => {
  const token = window.localStorage.getItem(LocalStorageKeyEnum.access_token);
  if (token) {
    const decoded = jwt.decode(token);

    return decoded as AccessToken;
  }

  return null;
};

export const removeAccessToken = (): void =>
  window.localStorage.removeItem(LocalStorageKeyEnum.access_token);

export const removeRefreshToken = (): void =>
  window.localStorage.removeItem(LocalStorageKeyEnum.refresh_token);

export { isAwaitingSignature } from "../adapters/web3Adapter";
