import { useState } from "react";
import { WalletConnectors, WalletWidget } from "./components";
import WalletAccountButton from "./components/Wallet/WalletAccountButton";
import ReactModal from "react-modal";
import networksConfig from "./supportedChainsConfig";
import ProviderMenuWrapper from "./containers/ProviderMenuWrapper";
import { dropAuthentication } from "./services/authService";
import AuthWrapperWithContext from "./containers/AuthWrapperWithContext";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { provider } from "web3-core";

const walletConnectors = new WalletConnectors([1, 56, 137]);
function App() {
  const [isProviderMenuOpen, setIsProviderMenuOpen] = useState(false);

  const onDisconnect = async (): Promise<void> => {
    dropAuthentication("https://auth-api-stage.dex.guru/v2");
  };

  function getLibrary(provider: provider): Web3 {
    return new Web3(provider);
  }
  return (
    <div className="App">
      <Web3ReactProvider getLibrary={getLibrary}>
        <AuthWrapperWithContext
          isOpenProviderMenu={isProviderMenuOpen}
          onOpenProviderMenu={() => setIsProviderMenuOpen(true)}
          onCloseProviderMenu={() => setIsProviderMenuOpen(false)}
        >
          <WalletWidget
            networksConfig={networksConfig}
            walletConnectors={walletConnectors}
            isLoading={false}
            onDisconnect={onDisconnect}
            openProviderMenu={(): void => {
              setIsProviderMenuOpen(true);
            }}
          />

          {isProviderMenuOpen ? (
            <ReactModal
              parentSelector={() => document.body}
              isOpen={isProviderMenuOpen}
              onRequestClose={(): void => {
                setIsProviderMenuOpen(false);
              }}
              overlayClassName="modal-overlay"
              className="modal-content"
            >
              <ProviderMenuWrapper
                walletConnectors={walletConnectors}
                networksConfig={networksConfig}
                closeAfterSelect={false}
                onDisconnect={onDisconnect}
              />
            </ReactModal>
          ) : (
            <></>
          )}
        </AuthWrapperWithContext>
      </Web3ReactProvider>
    </div>
  );
}

export default App;
