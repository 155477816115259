export const setSuppressEagerConnection = (value: boolean): void => {
  if (value) {
    localStorage.setItem(NOT_EAGER_KEY, 'true')
  } else {
    localStorage.removeItem(NOT_EAGER_KEY)
  }
}

export const isSuppressEagerConnect = (): boolean => {
  // SSR friendly
  return !!global.localStorage?.getItem(NOT_EAGER_KEY)
}

const NOT_EAGER_KEY = 'suppress_eager_wallet_connect'
