import { FC } from 'react'

export const ProviderMenuDisclaimer: FC = () => {
  return (
    <div className="provider-menu__disclaimer">
      <span className="part">By connecting wallet you agree</span>
      <span className="part">
        to the{' '}
        <a
          href="https://docs.dex.guru/legal/terms-of-service"
          target="_blank"
          rel="noopener noreferrer">
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href="https://docs.dex.guru/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </a>
      </span>
    </div>
  )
}

export default ProviderMenuDisclaimer
