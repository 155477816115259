import React from "react";
import { NetworksConfig } from "../supportedChainsConfig";

const ScanIcon = (props: {
  networksConfig: NetworksConfig;
  className?: string;
}): React.ReactElement | null => {
  return (
    <img
      className={props.className}
      src={props.networksConfig.block_explorer.logo_uri}
      alt={props.networksConfig.block_explorer.display_name}
    />
  );
};

export default ScanIcon;
