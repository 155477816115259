import { ProviderName } from "./types";

const CURRENT_PROVIDER_KEY = "currentProvider";

export const setCurrentProvider = (name: ProviderName): void =>
  localStorage.setItem(CURRENT_PROVIDER_KEY, name);

export const getCurrentProvider = (): ProviderName | null => {
  const providerNameString = localStorage.getItem(CURRENT_PROVIDER_KEY);
  return providerNameString
    ? ProviderName[providerNameString as ProviderName]
    : null;
};
