import { InjectedConnector } from "@web3-react/injected-connector";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import { setSuppressEagerConnection } from "./eagerConnect";
import { ProviderName } from "./types";
import { Address } from "../models/model";

class WalletConnectors {
  private connectors: Record<ProviderName, AbstractConnector | undefined> = {
    MetaMask: undefined,
    TrustWallet: undefined,
    Unknown: undefined,
  };
  private injectedConnector: InjectedConnector;

  constructor(chainIds: number[]) {
    this.injectedConnector = this.createInjectedConnector(chainIds);
    this.connectors[ProviderName.MetaMask] = this.injectedConnector;
    this.connectors[ProviderName.TrustWallet] = this.injectedConnector;
  }

  private createInjectedConnector = (chainIds: number[]): InjectedConnector =>
    new InjectedConnector({
      supportedChainIds: chainIds,
    });

  getInjectedConnector = (): InjectedConnector => this.injectedConnector;

  getConnectors = (): Record<ProviderName, AbstractConnector | undefined> =>
    this.connectors;

  closeAllConnectors = async (): Promise<void> => {
    for (const k in this.connectors) {
      const connector = this.connectors[k as ProviderName];
      if (connector instanceof WalletConnectConnector) {
        await connector?.close();
      }
      await connector?.deactivate();
    }
  };

  closeAllConnectorsButGiven = async (
    givenConnector: AbstractConnector
  ): Promise<void> => {
    for (const k in this.connectors) {
      const connector = this.connectors[k as ProviderName];
      if (givenConnector === connector) {
        return;
      }
      if (connector instanceof WalletConnectConnector) {
        await connector?.close();
      }
      await connector?.deactivate();
    }
  };

  disconnect = async (
    connector: AbstractConnector | undefined,
    deactivate: () => void
  ): Promise<void> => {
    if (!connector) {
      return;
    }
    await this.closeAllConnectors();
    deactivate();

    setSuppressEagerConnection(true);
  };

  connect = async (
    currentConnector: AbstractConnector,
    activate: (connector: AbstractConnector, cb: () => void) => Promise<void>,
    onError?: (error: Error) => void
  ): Promise<void> => {
    await this.closeAllConnectorsButGiven(currentConnector);

    await activate(currentConnector, onError || console.error);
    setSuppressEagerConnection(false);
  };

  getProviderName = async (
    connector?: AbstractConnector
  ): Promise<ProviderName | undefined> => {
    if (!connector) {
      return undefined;
    }

    for (const k in this.connectors) {
      const knownConnector = this.connectors[k as ProviderName];
      if (connector instanceof InjectedConnector) {
        return this.getInjectedProviderName(connector);
      }
      if (connector === knownConnector) {
        return ProviderName[k as ProviderName];
      }
    }
  };

  getInjectedProviderName = async (
    connector: InjectedConnector
  ): Promise<ProviderName | undefined> => {
    const provider = await connector.getProvider();
    if (provider.isTrust) {
      return ProviderName.TrustWallet;
    }
    if (provider.isMetaMask) {
      return ProviderName.MetaMask;
    }
  };

  disconnectProvider = async (
    account: Address | null | undefined,
    connector: AbstractConnector | undefined,
    deactivate: () => void,
    onDisconnect?: (account: string, currentProviderName: ProviderName) => void
  ): Promise<void> => {
    this.disconnect(connector, deactivate);

    if (account && connector) {
      const currentProviderName = await this.getProviderName(connector);
      if (onDisconnect && currentProviderName) {
        await onDisconnect(account, currentProviderName);
      }
    }
  };

  getProviderConnector = (
    providerName: ProviderName
  ): AbstractConnector | undefined => this.connectors[providerName];
}

export default WalletConnectors;
