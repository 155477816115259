export interface LoginResponse {
  access_token: string | null;
  refresh_token: string | null;
  nonce: string | null;
  account_id: string | null;
}

// Refresh will only return access token
// the interface is compatible to reuse response processing
export interface RefreshResponse {
  access_token: string;
  refresh_token?: string;
  nonce?: string;
  account_id?: string;
}

export const loginRequest = async (
  url: string,
  wallet: string,
  accountId?: string,
  nonce?: string,
  signature?: string | null
): Promise<LoginResponse | undefined> => {
  try {
    const response = await fetch(`${url}/auth/login`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        wallet,
        nonce: nonce || "",
        signature: signature || "",
        account_id: accountId || "",
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json() as Promise<LoginResponse>;
  } catch (error) {
    console.log("login request error", error);
    throw error;
  }
};

export const refreshRequest = async (
  url: string,
  refreshToken: string,
  headers?: {
    [key: string]: string;
  }
): Promise<RefreshResponse | undefined> => {
  try {
    const response = await fetch(`${url}/auth/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json() as Promise<RefreshResponse>;
  } catch (error) {
    console.log("refresh request error", error);
    throw error;
  }
};

// do we use it anywhere?
export const revokeToken = async (
  url: string,
  accessToken: string
): Promise<void> => {
  fetch(`${url}/auth/access-revoke`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
