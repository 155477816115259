import React, { FC } from "react";

import { Logo, ProviderName } from "../types";
import WalletConnectors from "../WalletConnectors";

const ProviderMenuEntryContents: FC<{ caption: string; icon: FC }> = ({
  caption,
  icon,
}) => {
  const Icon = icon;
  return (
    <>
      <span className="icon">
        <Icon />
      </span>
      <span className="name">{caption}</span>
    </>
  );
};

export const ProviderMenuList: FC<{
  isMobile: boolean;
  connectors?: WalletConnectors;
  providerLogos: Record<ProviderName, Logo>;
  onConnect: (ProviderName: ProviderName) => void;
}> = ({ isMobile, connectors, providerLogos, onConnect }) => {
  const agent = window.ethereum;
  const agentIsMetaMask = !!agent && !!agent.isMetaMask;
  const agentIsTrustWallet = !!agent && !!agent.isTrust;
  // const agentIsWalletConnect = !agentIsMetaMask && !agentIsTrustWallet;
  const allConnectors = connectors?.getConnectors();
  return (
    <div className="provider-menu-providers">
      {allConnectors &&
        (Object.keys(allConnectors) as (keyof typeof allConnectors)[]).map(
          (providerName: ProviderName) => {
            if (!providerName || providerName === ProviderName.Unknown) {
              return null;
            }

            if (isMobile) {
              if (
                agentIsTrustWallet &&
                providerName !== ProviderName.TrustWallet
              ) {
                return null;
              }
              if (agentIsMetaMask && providerName !== ProviderName.MetaMask) {
                return null;
              }
            } else {
              if (providerName === ProviderName.TrustWallet) {
                return null;
              }
            }

            const providerLogo: Logo | undefined = providerLogos[providerName];

            if (!providerLogo) {
              return null;
            }

            const subtitle = agent
              ? "Use this 👆 button if you are using dApp browser"
              : "Use this 👆 button for mobile wallets: MetaMask Mobile, Trust  Wallet,  Rainbow, Argent, etc";

            return (
              <div
                className="provider-menu-providers__entry"
                key={providerName}
              >
                {!isMobile &&
                !agent &&
                providerName === ProviderName.MetaMask ? (
                  <a
                    className="provider-menu-providers__button provider-menu-providers__button--install"
                    href="https://metamask.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ProviderMenuEntryContents
                      caption="Install MetaMask"
                      icon={providerLogo}
                    />
                  </a>
                ) : (
                  <>
                    <button
                      className="provider-menu-providers__button"
                      onClick={(): void => {
                        onConnect(providerName);
                      }}
                    >
                      <ProviderMenuEntryContents
                        caption={providerName.toString()}
                        icon={providerLogo}
                      />
                    </button>
                    <div className="provider-menu-providers__subtitle">
                      {subtitle}
                    </div>
                  </>
                )}
              </div>
            );
          }
        )}
    </div>
  );
};

export default ProviderMenuList;
